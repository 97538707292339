import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  InputGroup,
  Toast,
} from "react-bootstrap";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { dateToString } from "./services/UtilityService";
import {
  fetchSettings,
  fetchAllSubcategories,
  fetchSalesReport,
  putSetting,
  postSetting,
} from "./services/DataService";
import { SaleChart } from "./components/SaleChart";
import LoadMask from "./components/LoadMask";
import styles from "./style/sale-report.module.scss";

const DISCOUNT_SETTING_NAME = "PercentageDiscount";
const CHART_RESOLUTION_MINUTES = 10;
const REFRESH_RATE_SECONDS = 10;
let isSuperuser = false;

const CSV_HEADERS = [
  { label: "SaleDate", key: "sale_date" },
  { label: "SaleID", key: "sale_id" },
  { label: "SaleItemID", key: "sale_item_id" },
  { label: "FoundItemID", key: "found_item_id" },
  { label: "Subcategory", key: "subcategory" },
  { label: "TicketNumber", key: "ticket_number" },
  { label: "SaleItemAmount", key: "sale_item_amount" },
];

class SaleReport extends React.Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    if (user !== null) {
      user = JSON.parse(user);
      if (user.is_superuser) {
        isSuperuser = true;
      }
    }

    let today = new Date();
    const offset = today.getTimezoneOffset();
    today = new Date(today.getTime() - offset * 60 * 1000);

    this.state = {
      loading: true,
      toastMessage: "",
      date: today,
      discount: 0,
      total: 0,
      count: 0,
      sales: [],
      csv_data: "",
      subcategoryMap: [],
    };
  }

  componentDidMount() {
    fetchAllSubcategories().then((subcategories) => {
      let subcategoryMap = {};
      subcategories.forEach((subcategory) => {
        subcategoryMap[subcategory["ID"]] = subcategory;
      });
      this.setState({ subcategoryMap: subcategoryMap });
    });
    this.fetchData();
    // set timer for refresh
    setInterval(this.fetchData, REFRESH_RATE_SECONDS * 1000);
  }

  fetchData = () => {
    // get all sales by date YY-mm-dd
    // get current discount setting
    if (isSuperuser) {
      fetchSettings([`name=${DISCOUNT_SETTING_NAME}`]).then((discount) => {
        if (discount && discount.length === 1) {
          discount = discount[0];
        } else {
          discount = { name: DISCOUNT_SETTING_NAME, value: 0 };
        }
        this.setState({ discount: discount });
      });
    }

    let sale_date = this.state.date.toISOString().split("T")[0];
    fetchSalesReport([`sale_date=${sale_date}`]).then((sales) => {
      this.updateChart(sales);
      let count = 0;
      sales.forEach((sale) => {
        sale.sale_items.forEach((sale_item) => {
          count = count + 1;
        });
      });
      this.setState(
        {
          sales: sales,
          count: count,
          loading: false,
        },
        this.recalculate
      );
    });
  };

  updateChart = (sales) => {
    if (!sales || sales.length === 0) {
      return;
    }
    let data = [["Time (10m)", "$ Raised  ."]];
    let currentDate;
    let startDate = new Date(sales[sales.length - 1].sale_date);
    let nextDate = new Date(
      startDate.getTime() + CHART_RESOLUTION_MINUTES * 60000
    );
    let accumulator = 0;
    sales.slice().forEach((sale, i) => {
      currentDate = new Date(sale.sale_date);
      if (currentDate >= startDate && currentDate < nextDate) {
        accumulator = accumulator + sale.amount;
      } else {
        data.push([i, accumulator]);
        accumulator = sale.amount;
        startDate = nextDate;
        nextDate = new Date(
          startDate.getTime() + CHART_RESOLUTION_MINUTES * 60000
        );
      }
    });
    data.push([sales.length, accumulator]);
    this.setState({ data: data });
  };

  recalculate = () => {
    let sales = this.state.sales;
    let total = sales.reduce((acc, curr) => acc + curr.amount, 0);
    // calculate totals/quantities by subcategory ?
    if (total !== this.state.total) {
      this.setState({ total: total });
    }
  };

  submitDiscountCallback = (result) => {
    if (result && result.message === "updated") {
      this.setState({
        toastMessage: `Discount set to ${this.state.discount.value}%`,
      });
    } else {
      this.setState({
        toastMessage: `Huh, that didn't seem to work :(`,
      });
    }
  };

  submitDiscount = () => {
    let discount = this.state.discount;
    if (discount.id) {
      putSetting(discount).then(this.submitDiscountCallback);
    } else {
      postSetting(discount).then(this.submitDiscountCallback);
    }
  };

  getCSVData = (event, done) => {
    let csv_data = [];
    let sales = this.state.sales;
    sales.sort((a, b) => (a.id > b.id ? 1 : -1));
    this.state.sales.forEach((sale) => {
      sale.sale_items.forEach((sale_item) => {
        csv_data.push({
          sale_id: sale.id,
          sale_date: new Date(sale.sale_date),
          sale_item_id: sale_item.id,
          found_item_id: sale_item.found_id,
          subcategory: this.state.subcategoryMap[sale_item.subcategory_id].Name,
          ticket_number: sale_item.ticket,
          sale_item_amount: sale_item.amount,
        });
      });
    });
    this.setState({ csv_data: csv_data }, () => {
      done(true);
    });
  };

  render() {
    if (this.state.loading) {
      return <LoadMask />;
    }

    return (
      <Container className={styles["sale-report"]}>
        <Toast
          className={`d-inline-block ${styles["sale-toast"]} m-1 success`}
          onClose={() => this.setState({ toastMessage: "" })}
          show={this.state.toastMessage !== ""}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body>{this.state.toastMessage}</Toast.Body>
        </Toast>

        <Row className="align-items-center mt-3 mb-3">
          <Col className="title pl-0">Sale Report</Col>
          <Col md="auto" className={styles["set-date"]}>
            <InputGroup>
              <InputGroup.Text className={styles["input-group-left"]}>
                Date
              </InputGroup.Text>
              <DatePicker
                className={`form-control mb-0 ${styles["input-group-right"]}`}
                selected={this.state.date}
                onChange={(date) => this.setState({ date: date })}
              />
            </InputGroup>
          </Col>
          {isSuperuser ? (
            <Col md="auto" className={styles["set-discount"]}>
              <InputGroup>
                <InputGroup.Text className={styles["input-group-left"]}>
                  Discount
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  className={styles["input-group-middle"]}
                  defaultValue={this.state.discount.value}
                  onChange={(e) => {
                    let discount = this.state.discount;
                    discount.value = e.target.value;
                    this.setState({ discount: discount });
                  }}
                />
                <InputGroup.Text className={styles["input-group-middle"]}>
                  %
                </InputGroup.Text>
                <Button
                  variant="primary"
                  className={styles["input-group-right"]}
                  onClick={this.submitDiscount}
                >
                  Submit
                </Button>
              </InputGroup>
            </Col>
          ) : (
            ""
          )}
          {isSuperuser ? (
            <Col md="auto" className="pr-0">
              <CSVLink
                data={this.state.csv_data}
                asyncOnClick={true}
                onClick={this.getCSVData}
                headers={CSV_HEADERS}
                filename={`sale-${dateToString(this.state.date)}.csv`}
                className="btn btn-primary"
                target="_blank"
              >
                CSV
              </CSVLink>
            </Col>
          ) : (
            ""
          )}
        </Row>

        <Row
          className={`justify-content-md-center mb-4 p-4 ${styles["total"]}`}
        >
          <Col>
            <CountUp
              className={`${styles["total-count"]} pr-2`}
              end={this.state.total.toFixed(2)}
              prefix="$"
              suffix=" raised"
              decimals={2}
              duration={REFRESH_RATE_SECONDS}
              preserveValue={true}
            />
          </Col>
          <Col>
            <CountUp
              className={`${styles["item-count"]} pr-2`}
              end={this.state.count}
              suffix=" items sold"
              duration={REFRESH_RATE_SECONDS}
              preserveValue={true}
            />
          </Col>
        </Row>

        <Row className={styles["breakdown"]}>
          <Col md={10} className={styles["graphs"]}>
            <Row className={`${styles["line-chart"]} p-4`}>
              <SaleChart data={this.state.data} />
            </Row>
            <Row className={styles["categories"]}>
              <Col className={styles["by-total"]}>
                {/* Map Row Top 5 categories */}
              </Col>
              <Col className={styles["by-quantity"]}>
                {/* Map Row Top 5 categories */}
              </Col>
            </Row>
          </Col>
          <Col className={styles["transactions"]}>
            {this.state.sales.map((sale) => (
              /* Map Row Last 10 Transactions */
              <Row className={styles["sale-row"]} key={sale.id}>
                <Col md="auto" className="ml-2">
                  {sale.sale_items.map((item) => {
                    let src = require("./images/subcategories/" +
                      item.subcategory_id +
                      ".svg");
                    return (
                      <Row className={styles["sale-item-row"]} key={item.id}>
                        <Col md="auto">
                          <Image
                            className={styles["sale-item-image"]}
                            src={src}
                          />
                        </Col>
                        <Col md="auto">${item.amount.toFixed(2)}</Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SaleReport;
