import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

// Initialize the React plugin.
const reactPlugin = new ReactPlugin();

// Define a telemetry initializer to augment telemetry data with additional properties.
const telemetryInitializer = (envelope) => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  if (user) {
    envelope.data.userId = user.id || "";
    envelope.data.userName = `${user.first_name} ${user.last_name}` || "";
    envelope.data.userEmail = user.email || "";
    if (envelope.tags) {
      envelope.tags["ai.user.id"] = user.id;
    }
  }
};

// Create an instance of ApplicationInsights.
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY || "",
    enableAutoRouteTracking: true, // Automatically track page views
    extensions: [reactPlugin],
    disableFetchTracking: false,
    disableAjaxTracking: false,
  },
});

// Add the telemetry initializer.
appInsights.addTelemetryInitializer(telemetryInitializer);

// Set the authenticated user context.
appInsights.setAuthenticatedUserContext(localStorage.getItem("user") || "");

// Load and start Application Insights.
appInsights.loadAppInsights();

// Export the reactPlugin for use with the React App component.
export { appInsights, reactPlugin };
