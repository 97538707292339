import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import "./style/App.scss";
import "./style/glyphicon.scss";
import Home from "./Home.js";
import Lost from "./Lost.js";
import Found from "./Found";
import AddLost from "./AddLost";
import AddFound from "./AddFound";
import Report from "./Report";
import Sale from "./Sale";
import SaleReport from "./SaleReport";
import Admin from "./Admin";
import Header from "./Header";
import Footer from "./Footer";
import Error from "./Error";
import Warning from "./Warning";
import RouteError from "./RouteError";
import PrivateRoute from "./PrivateRoute";
import { Login, Logout } from "./services/AuthService";
import "./services/InsightsService";

const handlePopState = (event) => {
  event.preventDefault();
};

function App() {

  const { accounts } = useMsal();
  
  // @todo: use instance instead of session  
  if (!!accounts && accounts[0]) {  
    if (accounts[0].idToken) {
      window.sessionStorage.setItem("id_token", accounts[0].idToken);
    }
    if (accounts[0].accessToken) {
      window.sessionStorage.setItem("access_token", accounts[0].accessToken);
    }
  }

  window.onpopstate = handlePopState;
  return (
    <BrowserRouter>
      <Header />

      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lost/add/" element={<AddLost />} />
          <Route path="/login/" element={<Login />} />
          <Route path="/logout/" element={<Logout />} />
          <Route path="/error/:msg" element={<Error />} />
          <Route path="/warning/:msg" element={<Warning />} />
          <Route path="*" element={<RouteError />} />
        </Routes>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Routes>
          <Route
            path="/found/:id"
            element={
              <PrivateRoute>
                <AddFound />
              </PrivateRoute>
            }
          />
          <Route
            path="/found/add/"
            element={
              <PrivateRoute>
                <AddFound />
              </PrivateRoute>
            }
          />
          <Route
            path="/found/"
            element={
              <PrivateRoute>
                <Found />
              </PrivateRoute>
            }
          />
          <Route
            path="/sale/"
            element={
              <PrivateRoute>
                <Sale />
              </PrivateRoute>
            }
          />
          <Route
            path="/sale/report/"
            element={
              <PrivateRoute>
                <SaleReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/lost/add"
            element={
              <PrivateRoute>
                <AddLost />
              </PrivateRoute>
            }
          />
          <Route
            path="/lost/:id"
            element={
              <PrivateRoute>
                <AddLost />
              </PrivateRoute>
            }
          />
          <Route
            path="/lost/"
            element={
              <PrivateRoute>
                <Lost />
              </PrivateRoute>
            }
          />
          <Route
            path="/report/"
            element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Home />} />
          <Route path="/login/" element={<Navigate to="/found" />} />
          <Route path="/logout/" element={<Logout />} />
          <Route path="/error/:msg" element={<Error />} />
          <Route path="/warning/:msg" element={<Warning />} />
          <Route path="*" element={<RouteError />} />
        </Routes>
      </AuthenticatedTemplate>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
