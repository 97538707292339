let ticketMaster = () => {
  // return random 10 character alphanumeric
  let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let length = 10;
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

let dateToString = d => {
  if (!d) {
    d = new Date();
  }
  var yyyy = d.getFullYear().toString();
  var mm = (d.getMonth() + 1).toString();
  var dd = d.getDate().toString();
  return (
    yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0])
  );
};

let stringToDate = s => {
  let date = s.split("T");
  let parts = date[0].split("-");
  return new Date(parts[0], parseInt(parts[1], 10) - 1, parts[2]);
};

let attributesFromString = s => {
  let o = {};
  if (!s) {
    return o;
  }
  s.substring(1)
    .split("_")
    .forEach(a => {
      o[a.split("=")[0]] = a.split("=")[1];
    });
  return o;
};

export { ticketMaster, dateToString, stringToDate, attributesFromString };
