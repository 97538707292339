import React from "react";
import { Button, Image } from "react-bootstrap";
import { appInsights } from "./services/InsightsService";
import infodesk from "./images/lostfound.png";
import styles from "./style/home.module.scss";

const Home = () => {
  return (
    <div id="main-content">
      <div className={styles["heading"]}>
        <h1>Lost and Found</h1>
      </div>
      <div className={styles["top-section"]}>
        <div className={styles["add-lost"]}>
          <h2 className={styles["label"]}>Lose something?</h2>
          <div className={styles["top-desc"]}>
            Visit the Lost and Found desk near the elevators on the VU 6th Floor
            Lobby to claim your item, or submit a Lost Item Report.
          </div>
          <div className={styles["add-lost-button-container"]}>
            <Button
              onClick={() => {
                appInsights.trackEvent({
                  name: "buttonClickReportLost",
                  properties: {
                    buttonText: "Report",
                  },
                });
                window.location.href = "/lost/add";
              }}
              className={`${styles["add-lost-button"]} ${styles["wwu-button"]}`}
            >
              Report
            </Button>
          </div>
        </div>
        <div className={styles["infodesk"]}>
          <Image
            src={infodesk}
            alt="Stylized blue photo of the lost and found office."
          />
        </div>
      </div>

      <div className={styles["desc-info"]}>
        <h2
          className={`${styles["desc-title"]} ${styles["label"]} ${styles["bold"]}`}
        >
          Lost &amp; Found
        </h2>
        At Western Washington University, the Viking Union Lost and Found
        service is dedicated to reuniting people with their misplaced
        belongings. If you've lost an item, don't worry! You can visit the Lost
        and Found desk, conveniently located near the elevators on the 6th Floor
        Lobby of the Viking Union (VU), to check if your item has been turned
        in. We also encourage you to submit a Lost Item Report to increase the
        chances of finding your lost possessions. We understand how frustrating
        it can be to lose something important, which is why our team works
        diligently to ensure found items are logged and stored safely. Each item
        we receive is securely cataloged, providing peace of mind for those who
        may have left something behind.
      </div>

      <div className={styles["bottom-section"]}>
        <div className={styles["light-blue-bg"]}></div>
        <div className={styles["desc"]}>
          <div className={styles["find"]}>
            <h2 className={styles["label"]}>Find something?</h2>
            Return It to Help Others! If you've come across a misplaced item on
            campus, we invite you to bring it to the Info Desk in the VU 6th
            Floor Lobby. Our Lost and Found staff will work to return it to its
            rightful owner. By participating in this process, you're helping to
            maintain the integrity of our community and making it easier for
            students, staff, and visitors to recover their belongings.
          </div>
        </div>
        <div className={styles["hours"]}>
          <h2 className={styles["label"]}>Hours</h2>
          <div>Our Lost and Found office is open</div>
          <div className={styles["hours-times"]}>Mon-Sat 9:00am-9:00pm</div>
          <div className={styles["hours-times"]}>Sun 10:00am-9:00pm</div>
          <div className={styles["hours-addendum"]}>
            Please note that the office is closed during intersession breaks and
            holidays.
          </div>
        </div>
        <div className={styles["contact"]}>
          <h2 className={styles["label"]}>Contact</h2>
          <div>
            For any questions or to report a lost item, feel free to contact us:
          </div>
          <div className={styles["contact-info"]}>Phone 360.650.3120</div>
          <div className={styles["contact-info"]}>
            Email&nbsp;
            <a
              href="mailto:vu.lostfound@wwu.edu"
              style={{ fontWeight: "bold" }}
            >
              vu.lostfound@wwu.edu
            </a>
          </div>
        </div>
        <div className={styles["grey-bg"]}></div>
        <div className={styles["login"]}>
          <h2 className={styles["label"]}>Lost & Found Employee?</h2>
          <div className={styles["login-info"]}>
            If you're a Lost and Found employee, you can log in here to manage
            items.
          </div>
          <div className={styles["button-container"]}>
            <Button
              onClick={() => {
                appInsights.trackEvent({
                  name: "buttonClickLogin",
                  properties: {
                    buttonText: "Login as an employee",
                  },
                });
                window.location.href = "/login";
              }}
              className={`${styles["login-button"]} ${styles["wwu-button"]}`}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
