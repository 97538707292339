import React, { useState, useEffect } from "react";
import { fetchSelf } from "./services/DataService";
import { useIsAuthenticated } from "@azure/msal-react";

const MainMenu = () => {
  const isAuthenticated = useIsAuthenticated();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    if (isAuthenticated && !user) {
      fetchSelf().then((fetchedUser) => {
        if (fetchedUser == null || fetchedUser === "undefined") {
          localStorage.removeItem("user");
          setUser(null);
        } else {
          localStorage.setItem("user", JSON.stringify(fetchedUser));
          setUser(fetchedUser);
        }
      });
    }
  }, [isAuthenticated, user]);

  const path = window.location.pathname.split("/")[1];
  const selectedIndex =
    {
      "": 0,
      lost: 1,
      found: 2,
      report: 3,
      admin: 4,
    }[path] || 0;

  if (!isAuthenticated) {
    return <div></div>;
  }

  return (
    <div id="main-menu">
      <div className="region region-navigation">
        <div
          id="block-system-main-menu"
          className="block block-system block-system-main-menu block-menu first last odd"
        >
          <ul className="menu">
            <li className="menu__item is-leaf first leaf">
              <a
                href="/"
                className={`menu__link glyphicon glyphicon-home ${
                  selectedIndex === 0 ? "active" : ""
                }`}
              >
                <div className="hidden">Home</div>
              </a>
            </li>
            <li className="menu__item is-collapsed collapsed">
              <a
                href="/lost"
                className={`menu__link ${selectedIndex === 1 ? "active" : ""}`}
              >
                Lost
              </a>
            </li>
            <li className="menu__item is-collapsed collapsed">
              <a
                href="/found"
                className={`menu__link ${selectedIndex === 2 ? "active" : ""}`}
              >
                Found
              </a>
            </li>
            {user && (user.is_superuser || user.is_reporter) && (
              <li className="menu__item is-collapsed collapsed">
                <a
                  href="/report"
                  className={`menu__link ${
                    selectedIndex === 3 ? "active" : ""
                  }`}
                >
                  Report
                </a>
              </li>
            )}
            {user && user.is_superuser && (
              <li className="menu__item is-collapsed collapsed">
                <a
                  href="/admin"
                  className={`menu__link ${
                    selectedIndex === 4 ? "active" : ""
                  }`}
                >
                  Admin
                </a>
              </li>
            )}
            <li className="menu__item is-collapsed collapsed">
              <a
                href="https://wwu2.sharepoint.com/:o:/s/VikingUnionTechServices/EiUhuTv9yZxNj3EPDnyBmqAB5fZeBdelRkhj32d9h8iaTA"
                className="menu__link glyphicon glyphicon-question-sign"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="hidden">Documentation</div>
              </a>
            </li>
            <li className="menu__item is-collapsed collapsed">
              <a
                href="/logout"
                className="menu__link glyphicon glyphicon-log-out"
              >
                <div className="hidden">Logout</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
