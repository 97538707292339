import React from "react";
import "./style/footer.scss";

function Footer() {
  return (
    <footer role="contentinfo" className="page-footer region region--footer">
      <section aria-label="Western footer" className="wwu-footer">
        <div className="logo stacked">
          <span className="visually-hidden">Western logo</span>
          <svg
            version="1.2"
            baseProfile="tiny"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 152.53 97.64"
            space="preserve"
            role="presentation"
            aria-hidden="true"
          >
            <title>Western Washington University Logo</title>
            <path
              className="cls-5"
              d="M.15,39.33c1.76,0,3.2.18,5.54.18,2.53,0,3.74-.09,4.78-.13a.87.87,0,0,1,0,.67,9.18,9.18,0,0,0-1.85.45c-.58.27-.76.77-.45,1.94C9.25,46.9,12,56.27,13.53,61.36a.56.56,0,0,0,.14-.09c1.58-4.37,6.17-17.39,7-20.72a10.1,10.1,0,0,0,1.66,0c.81,3.43,5.82,17.35,7.26,21,0,0,.13,0,.18,0,1.13-3.56,4.37-14.23,5.14-18,.27-1.4.36-2.57-.81-2.93a21.1,21.1,0,0,0-2.53-.5c-.09-.13-.14-.58,0-.67.95,0,3.11.13,4.87.13,2.43,0,3.6-.09,4.5-.13a.59.59,0,0,1,0,.67,15.17,15.17,0,0,0-2.16.54c-1,.45-1.4,1.13-2,2.93C35,48.7,30.7,62.72,29.44,67.31a11,11,0,0,0-1.71,0c-1.13-4-5.19-15.5-7.26-21.18a.19.19,0,0,0-.18.09c-1.62,4.69-6.08,17.26-7.21,21a8.28,8.28,0,0,0-1.85,0C9.93,61.81,6.05,48,4.57,43.75c-.77-2.35-1.22-2.93-2.48-3.29-.72-.18-1.17-.27-2-.41C0,40,0,39.42.15,39.33Z"
            ></path>
            <path
              className="cls-5"
              d="M37.77,47.31c.77,0,2.57.13,4.33.13h6.76a19.85,19.85,0,0,0,3.56-.18s.13.09.13.14A21.34,21.34,0,0,0,51.88,52a.89.89,0,0,1-.68,0A10.76,10.76,0,0,0,50.79,50c-.22-.9-.45-1.3-1.66-1.39-.81-.09-4.37,0-4.83,0-.22,0-.36.45-.36.58,0,2.21-.09,6.22,0,6.94a.21.21,0,0,0,.18.23c1.49,0,3.83,0,4.78,0S50,55.6,50.25,55a5.76,5.76,0,0,0,.27-1.27c.09,0,.68,0,.72,0,0,.45-.18,2.34-.18,3.2,0,.63.09,2.43.09,3.2a.79.79,0,0,1-.76,0,9.79,9.79,0,0,0-.27-1.35,1.4,1.4,0,0,0-1.26-1.13c-1-.09-3.47-.09-4.83-.09,0,0-.13.22-.13.27,0,.81,0,4.37,0,6.31.05,1,.55,1.44.77,1.48a22.75,22.75,0,0,0,4.33.23c1.84,0,2.25-.45,2.74-1a10.8,10.8,0,0,0,1.85-3.24c.14,0,.63.09.68.27a21.34,21.34,0,0,1-1.58,4.82c-.09.23-.36.32-1.22.32C49.8,67.09,47,67,42.14,67c-2.07,0-3.69.13-5.09.13a1,1,0,0,1,0-.72,11.77,11.77,0,0,0,2.48-.36c.76-.22,1-.5,1.08-1.8s0-4.28,0-8c0-3.06,0-5.72,0-6.31-.09-1-.27-1.35-1-1.62a9.88,9.88,0,0,0-1.8-.31C37.64,47.94,37.64,47.35,37.77,47.31Z"
            ></path>
            <path
              className="cls-5"
              d="M59.63,65.74a5.45,5.45,0,0,0,3.19.94c2,0,3.74-1,3.74-3.38,0-1.85-.85-3.06-4.41-4.91-3.29-1.76-4.87-3.29-4.87-6.13,0-3.56,3.25-5.41,6.76-5.41a21.73,21.73,0,0,1,3.06.23c.73.09,1.58.23,1.9.23a44.56,44.56,0,0,0-.54,4.77c-.09.09-.63.14-.68,0-.13-2-.59-3-1.31-3.56a4.69,4.69,0,0,0-2.92-.81A3.18,3.18,0,0,0,60.12,51a3.94,3.94,0,0,0,2.21,3.56c1.53.9,2.75,1.53,4.1,2.3,1.67.94,3.24,2.34,3.24,4.82,0,3.15-2.84,5.9-7.43,5.9a13.3,13.3,0,0,1-5-1,26.85,26.85,0,0,1-.72-5,1.07,1.07,0,0,1,.68-.18C57.82,63.66,58.63,65.06,59.63,65.74Z"
            ></path>
            <path
              className="cls-5"
              d="M77.6,66.14c1-.18,1.27-.72,1.31-2.25s.14-10,.14-15.14a.3.3,0,0,0-.23-.23c-.59,0-3.11,0-3.79,0a2,2,0,0,0-1.66,1.17,25,25,0,0,0-1.31,3.06c-.09,0-.63-.09-.67-.18.27-1.3.54-4.23.63-5.58,0,0,.09-.09.18,0a6.19,6.19,0,0,0,2.47.41H87.11A7.15,7.15,0,0,0,90,47a.14.14,0,0,1,.14.13,43.68,43.68,0,0,0-.63,5.68c-.09.09-.72.13-.72,0a12.58,12.58,0,0,0-.63-2.93,1.68,1.68,0,0,0-1.58-1.26c-1.17-.09-3.47-.14-4.14-.09a.27.27,0,0,0-.14.27c0,4.55,0,14.19.14,15.86a1.44,1.44,0,0,0,1,1.44c.5.14,1.08.18,1.94.32.13,0,.09.63-.05.67-.94,0-2.92-.13-5-.13-2.3,0-4,.18-4.91.13-.13-.09-.13-.63,0-.67A17.66,17.66,0,0,0,77.6,66.14Z"
            ></path>
            <path
              className="cls-5"
              d="M91.75,47.31c.77,0,2.57.13,4.33.13h6.76a19.7,19.7,0,0,0,3.56-.18s.13.09.13.14a22,22,0,0,0-.67,4.64.89.89,0,0,1-.68,0,10.83,10.83,0,0,0-.4-2.08c-.23-.9-.46-1.3-1.67-1.39-.81-.09-4.37,0-4.82,0-.23,0-.36.45-.36.58-.05,2.21-.09,6.22-.05,6.94a.21.21,0,0,0,.18.23c1.49,0,3.83,0,4.78,0s1.17-.67,1.39-1.3a5.76,5.76,0,0,0,.27-1.27c.1,0,.68,0,.73,0,0,.45-.18,2.34-.18,3.2,0,.63.09,2.43.09,3.2a.81.81,0,0,1-.77,0,9.79,9.79,0,0,0-.27-1.35,1.4,1.4,0,0,0-1.26-1.13c-1-.09-3.47-.09-4.82-.09,0,0-.14.22-.14.27,0,.81,0,4.37.05,6.31,0,1,.54,1.44.76,1.48a22.75,22.75,0,0,0,4.33.23c1.85,0,2.25-.45,2.75-1a11,11,0,0,0,1.84-3.24c.14,0,.63.09.68.27a21.34,21.34,0,0,1-1.58,4.82c-.09.23-.36.32-1.21.32-1.72,0-4.56-.09-9.38-.09-2.07,0-3.69.13-5.09.13a1,1,0,0,1,0-.72,11.77,11.77,0,0,0,2.48-.36c.77-.22,1-.5,1.08-1.8s0-4.28,0-8c0-3.06,0-5.72,0-6.31-.09-1-.27-1.35-1-1.62a9.88,9.88,0,0,0-1.8-.31C91.62,47.94,91.62,47.35,91.75,47.31Z"
            ></path>
            <path
              className="cls-5"
              d="M110.05,47.31c.76,0,2.88.13,4.19.13h5.63c4,0,5.72,2.12,5.72,4.78s-2,4.23-4,5c-.05,0,0,.18,0,.22a49.92,49.92,0,0,0,5.63,7.62,4.06,4.06,0,0,0,3.16,1.44s0,.68-.09.68a15.29,15.29,0,0,1-5.27-.45,3.76,3.76,0,0,1-1.54-1.18c-1.26-1.39-3.46-5-4.68-6.84a1.27,1.27,0,0,0-1-.63,14.15,14.15,0,0,0-1.48,0c-.09,0-.14.18-.14.23,0,1.8.05,4.82.14,6s.36,1.58,1,1.81a10,10,0,0,0,1.8.31c.13.09,0,.63,0,.72-.67,0-2.56-.13-4.73-.13-2.34,0-3.83.13-4.46.13a1,1,0,0,1,0-.72c.68-.09,1.62-.18,1.94-.27.77-.18,1.08-.59,1.17-1.8,0-.86,0-5,0-6.72,0-2.61,0-6.93,0-8a1.16,1.16,0,0,0-1-1.26A14.93,14.93,0,0,0,110,48C109.87,47.94,109.91,47.35,110.05,47.31Zm6.17,1.62c0,1-.09,5.22-.09,7.84,0,.18.05.27.22.27a19.7,19.7,0,0,0,3.57-.14c1.17-.31,2.34-1.26,2.34-3.87,0-2.93-1.49-4.64-4.78-4.64a3.83,3.83,0,0,0-1,.13A.47.47,0,0,0,116.22,48.93Z"
            ></path>
            <path
              className="cls-5"
              d="M129.69,47.27c.59,0,2.75.13,4.19.13.72,0,1.81,0,2.12,0a6.84,6.84,0,0,0,1.13,1.71c3.38,4.28,9.82,12,11.26,13.65.05.05.09,0,.14-.09,0-4.19-.09-10.5-.23-12.57a1.64,1.64,0,0,0-1.35-1.8,7.49,7.49,0,0,0-1.67-.27,2.17,2.17,0,0,1,0-.72c.81,0,2.12.13,3.83.13,1,0,3-.13,3.38-.13a1.52,1.52,0,0,1,0,.67,10,10,0,0,0-1.57.32c-.82.22-.95.85-1,1.62-.17,2.43-.22,9-.22,13,0,1.44.05,3.29.09,4.28a20.94,20.94,0,0,0-2.39,0c-1.4-2-11.35-14.47-12.84-16.27-.05,0-.09,0-.09,0-.05,3.24,0,11.31,0,13.12.09,1.48.5,1.71,1.18,2a8.29,8.29,0,0,0,2,.36c.14.05.09.64,0,.73-.63,0-2.56-.14-4.19-.14s-2.93.14-3.6.14c-.14-.09-.14-.68,0-.73a9.1,9.1,0,0,0,2-.36c.77-.27.9-.94,1-1.93.14-1.44.27-7,.27-13.89a1.79,1.79,0,0,0-.67-1.48c-.59-.5-1.17-.68-2.84-.81A1.27,1.27,0,0,1,129.69,47.27Z"
            ></path>
            <path
              className="cls-5"
              d="M125.39,15.94s9.25,15.4,23.3,20.9C148.69,36.84,129.21,40.7,125.39,15.94Z"
            ></path>
            <path
              className="cls-5"
              d="M43.91,31.59S64.5,27,85.08,2.1C85.08,2.1,69.06,39.08,43.91,31.59Z"
            ></path>
            <path
              className="cls-5"
              d="M122.8,12.74s1.31,5.63-6.79,10.35c0,0-9.48-21.21-22.86-23.09,0,0,10.59,0,21.46,12.44C118.31,16.68,120.53,15.56,122.8,12.74Z"
            ></path>
            <path
              className="cls-2"
              d="M40.43,32.39s9,3,17.12,1.25c16.1-3.57,22.8-9.3,33.22-11.09s12.86-1,25.24.54c0,0-2.71-.48-9.47.68C88.34,26.9,78.7,35.56,63.19,36.69S40.43,32.39,40.43,32.39Z"
            ></path>
            <path
              className="cls-2"
              d="M43.91,35.69s17.26,5.22,26.63,3.14C89.26,34.68,97,28,109.14,25.94s16.85-1,31.23.84a44.32,44.32,0,0,0-12.9.58c-21.15,3.63-32.35,13.7-50.38,15S43.91,35.69,43.91,35.69Z"
            ></path>
            <path
              className="cls-5"
              d="M7.25,72.13c.61,0,1.25.05,2.06.05,1,0,1.46,0,1.83-.05.06,0,.06.32,0,.37a6.61,6.61,0,0,0-.72.17c-.24.08-.31.28-.21.65.35,1.48,1.28,4.7,1.81,6.53,0,0,.05,0,.06,0,.63-1.71,2.08-5.86,2.45-7.24a4.57,4.57,0,0,0,.74,0c.35,1.33,2,6,2.54,7.35h.07c.41-1.3,1.52-4.92,1.79-6.22.11-.52.14-.91-.32-1a4.79,4.79,0,0,0-.91-.17.4.4,0,0,1,0-.37c.35,0,1.15.05,1.79.05,1,0,1.41,0,1.74-.05a.48.48,0,0,1,0,.37,4.21,4.21,0,0,0-.73.17c-.37.16-.56.39-.79,1-.67,1.88-2.14,6.61-2.65,8.42a4.61,4.61,0,0,0-.74,0c-.37-1.38-1.87-5.65-2.53-7.43l-.06,0c-.64,1.84-2.08,5.88-2.55,7.37a3.79,3.79,0,0,0-.75,0c-.46-1.95-1.81-6.8-2.32-8.34-.26-.76-.42-1-.85-1.1a4.25,4.25,0,0,0-.78-.16S7.17,72.16,7.25,72.13Z"
            ></path>
            <path
              className="cls-5"
              d="M26.19,80.23c.88-2.08,2.37-6.18,2.73-7.2a6.2,6.2,0,0,0,.24-.87c.06,0,.24,0,.49,0s.43,0,.5,0c.05.21.13.48.22.79.34.94,2.1,6.17,2.61,7.46.47,1,.55,1.12,1.67,1.26a.53.53,0,0,1,0,.37c-.41,0-1.24-.05-1.89-.05s-1.63,0-2,.05a.67.67,0,0,1,0-.37,4.42,4.42,0,0,0,.82-.18c.24-.09.24-.24.19-.41-.19-.67-.72-2.15-1-2.92a.34.34,0,0,0-.13-.09c-.21,0-.67,0-1.45,0-1,0-1.16,0-1.33,0s-.13.08-.15.13a20,20,0,0,0-.91,2.73c0,.28,0,.48.48.61.19,0,.42.08.74.13A.67.67,0,0,1,28,82c-.34,0-1.14-.05-2-.05s-1.31.05-1.61.05a.48.48,0,0,1,0-.37C25.51,81.53,25.7,81.41,26.19,80.23Zm3-6.47c-.27.77-1,2.74-1.22,3.52a.12.12,0,0,0,.05.12h2.37s.06-.07,0-.13c-.34-1-.93-2.82-1.19-3.52C29.27,73.75,29.22,73.73,29.22,73.76Z"
            ></path>
            <path
              className="cls-5"
              d="M41,81.37a2.74,2.74,0,0,0,1.57.41,1.63,1.63,0,0,0,1.73-1.73c0-1-.67-1.6-2.08-2.45s-2.32-1.61-2.32-3c0-1.68,1.37-2.69,3.09-2.69a16.35,16.35,0,0,1,2.35.26,19.16,19.16,0,0,0-.27,2.16.48.48,0,0,1-.39,0c-.08-1-.24-1.44-.64-1.68a2.31,2.31,0,0,0-1.26-.3A1.62,1.62,0,0,0,41.12,74a2.08,2.08,0,0,0,1.07,1.84c.63.44,1.37.79,1.89,1.12a2.79,2.79,0,0,1,1.56,2.39c0,1.58-1.29,2.93-3.48,2.93a5.54,5.54,0,0,1-2.24-.48,12.51,12.51,0,0,1-.41-2.32.49.49,0,0,1,.4-.11A3.32,3.32,0,0,0,41,81.37Z"
            ></path>
            <path
              className="cls-5"
              d="M51.22,72.13c.43,0,1.23.05,2,.05,1,0,1.44,0,1.89-.05a.52.52,0,0,1,0,.37,5.87,5.87,0,0,0-.72.13c-.3.09-.43.22-.45.73s0,1.6,0,3.12a.15.15,0,0,0,.08.15c.08,0,.33,0,2.73,0l2.48,0s.08-.07.08-.1c0-.83,0-2.75,0-3.34a.51.51,0,0,0-.32-.53,4,4,0,0,0-.86-.16.33.33,0,0,1,0-.37c.35,0,1.09.05,1.93.05s1.62-.05,1.89-.05a.47.47,0,0,1,0,.37,5.88,5.88,0,0,0-.76.14c-.34.11-.45.26-.47.87s0,1.63,0,3.58c0,1.36,0,3.14,0,3.67s.13.64.43.73a3.77,3.77,0,0,0,1,.18c0,.06,0,.32-.06.37-.53,0-1.22-.05-2-.05S58.4,82,58,82a.47.47,0,0,1,0-.37,3.67,3.67,0,0,0,.84-.14c.32-.08.41-.24.43-.79s0-2.3,0-3.31c0-.05,0-.11-.08-.11-.27,0-1.66,0-2.51,0-1.28,0-2.58,0-2.72,0s-.06.08-.06.09c0,.87,0,2.8,0,3.16,0,.54.07.81.47.92a4.57,4.57,0,0,0,.91.18.41.41,0,0,1-.07.37c-.36,0-1.07-.05-2-.05s-1.72,0-2.07.05a.47.47,0,0,1,0-.37c.37,0,.61-.1.82-.14.46-.13.51-.45.54-1.19,0-.53,0-2,0-3.36,0-1.55,0-3.33,0-3.62,0-.48-.15-.62-.44-.7a5.49,5.49,0,0,0-.91-.16A.44.44,0,0,1,51.22,72.13Z"
            ></path>
            <path
              className="cls-5"
              d="M68.1,72.13c.35,0,1.2.05,2,.05s1.49-.05,1.86-.05a.52.52,0,0,1,0,.37c-.31,0-.58.08-.76.11-.35.11-.41.32-.44.83s0,2.1,0,3.49c0,1.64,0,3.17,0,3.73s.08.74.51.87l.88.14c.06,0,0,.32,0,.37C71.75,82,71,82,70.24,82c-.93,0-1.5.05-2.14.05-.07-.05,0-.32,0-.37a5.54,5.54,0,0,0,.75-.11c.43-.1.5-.32.54-.72s0-2.48,0-4.26c0-1.31,0-2.42,0-3.17,0-.38-.06-.66-.42-.75a4.79,4.79,0,0,0-.91-.16S68.07,72.18,68.1,72.13Z"
            ></path>
            <path
              className="cls-5"
              d="M78.31,72.13c.36,0,1.15.05,1.84.05a6.69,6.69,0,0,0,.73,0,4.39,4.39,0,0,0,.59.87c1.65,2.06,5,6.19,5.78,7.07l0,0c0-2.15,0-5.43-.06-6.53,0-.48-.13-.74-.59-.87a7.11,7.11,0,0,0-1-.17.48.48,0,0,1,0-.37c.41,0,1.12.05,2,.05.53,0,1.41-.05,1.6-.05a.44.44,0,0,1,0,.37,5.72,5.72,0,0,0-.66.14c-.43.13-.53.44-.54.87-.1,1.25-.08,3.82-.13,6.4,0,.74,0,1.65,0,2.16a8.68,8.68,0,0,0-1,0c-.82-1.18-5.45-6.95-6.29-8a.06.06,0,0,0-.07,0c0,1.64,0,5.56,0,6.44,0,.7.25.89.64,1,.24.06.56.09.91.14.07,0,0,.32,0,.37C81.75,82,81,82,80.19,82s-1.44.05-1.77.05a.47.47,0,0,1,0-.37,3,3,0,0,0,.77-.14c.36-.1.59-.29.62-1s.11-3.55.14-6.86a1,1,0,0,0-.4-.79,2.24,2.24,0,0,0-1.26-.38A.48.48,0,0,1,78.31,72.13Z"
            ></path>
            <path
              className="cls-5"
              d="M103.35,81.69a14.65,14.65,0,0,1-3.43.59c-3.66,0-5.63-1.81-5.63-5.27a4.74,4.74,0,0,1,2.8-4.51,7.24,7.24,0,0,1,3.11-.61,25.65,25.65,0,0,1,3,.35,16.7,16.7,0,0,0-.25,2.45.47.47,0,0,1-.43,0c-.13-1.19-.33-1.62-.79-1.89a4.19,4.19,0,0,0-2.18-.46c-2.49,0-3.74,1.76-3.74,4.48s1.49,4.93,4.32,4.93a2.72,2.72,0,0,0,1.09-.18.76.76,0,0,0,.4-.7,16.45,16.45,0,0,0,0-1.73c0-.56-.14-.75-.62-.85a9.15,9.15,0,0,0-1-.13.4.4,0,0,1,0-.38c.53,0,1.3,0,2.16,0s1.28,0,1.63,0a.46.46,0,0,1,0,.38,4.06,4.06,0,0,0-.52.12c-.3.09-.36.3-.4.83s0,1.45,0,1.79.07.32.39.43A1.12,1.12,0,0,1,103.35,81.69Z"
            ></path>
            <path
              className="cls-5"
              d="M116,82c-.48,0-1.4-.05-2.21-.05-1.11,0-1.92.05-2.36.05a.47.47,0,0,1,0-.37c.48,0,.87-.1,1.09-.14.48-.1.56-.42.6-1.16,0-1,0-4.89,0-7.5,0-.05,0-.12-.1-.12-.29,0-1.65,0-2.13,0a.72.72,0,0,0-.65.42,8,8,0,0,0-.61,1.45.53.53,0,0,1-.39-.09A25.2,25.2,0,0,0,109.6,72s.11-.08.15,0a2.92,2.92,0,0,0,1.26.23h5.91a3.67,3.67,0,0,0,1.49-.21s.11,0,.12.06a15.21,15.21,0,0,0-.35,2.57c0,.06-.38.06-.42,0a5.89,5.89,0,0,0-.23-1.36.63.63,0,0,0-.63-.45c-.67-.05-1.94,0-2.29,0a.14.14,0,0,0-.08.13c0,2.32,0,6.82.07,7.65s.19.86.54,1a6.92,6.92,0,0,0,.94.14A.43.43,0,0,1,116,82Z"
            ></path>
            <path
              className="cls-5"
              d="M124.75,80.81A5.57,5.57,0,0,1,123.38,77a5,5,0,0,1,1-3,5.41,5.41,0,0,1,4.26-2.06,4.7,4.7,0,0,1,4.86,5,5.42,5.42,0,0,1-1,3.3,5.08,5.08,0,0,1-4.18,2.1A4.81,4.81,0,0,1,124.75,80.81Zm5.91.28A4.59,4.59,0,0,0,132,77.43a5.26,5.26,0,0,0-1.83-4.31,3.54,3.54,0,0,0-2.11-.67,2.94,2.94,0,0,0-1.49.38,4.22,4.22,0,0,0-1.68,3.8,5.34,5.34,0,0,0,2,4.46,3.57,3.57,0,0,0,2,.58A2.73,2.73,0,0,0,130.66,81.09Z"
            ></path>
            <path
              className="cls-5"
              d="M138.72,72.13c.37,0,1.15.05,1.84.05a6.8,6.8,0,0,0,.74,0,3.57,3.57,0,0,0,.59.87c1.65,2.06,5,6.19,5.78,7.07l.05,0c0-2.15,0-5.43-.07-6.53,0-.48-.12-.74-.59-.87a7.19,7.19,0,0,0-1-.17.48.48,0,0,1,0-.37c.42,0,1.12.05,2,.05.53,0,1.41-.05,1.6-.05s0,.32,0,.37a5.5,5.5,0,0,0-.65.14c-.43.13-.53.44-.55.87-.09,1.25-.08,3.82-.12,6.4,0,.74,0,1.65,0,2.16a8.85,8.85,0,0,0-1,0c-.81-1.18-5.44-6.95-6.29-8,0,0-.06,0-.06,0,0,1.64,0,5.56,0,6.44,0,.7.26.89.64,1s.57.09.92.14c.06,0,0,.32,0,.37-.37,0-1.07-.05-1.92-.05s-1.44.05-1.78.05a.58.58,0,0,1,0-.37,2.87,2.87,0,0,0,.77-.14c.37-.1.59-.29.63-1s.11-3.55.14-6.86a1,1,0,0,0-.4-.79,2.24,2.24,0,0,0-1.26-.38A.48.48,0,0,1,138.72,72.13Z"
            ></path>
            <path
              className="cls-5"
              d="M8.26,86.56c.3,0,1.28,0,2.13,0s1.58-.06,2-.06a.58.58,0,0,1,0,.41,7.54,7.54,0,0,0-.77.15c-.37.11-.47.3-.51.93,0,1.56,0,4.24,0,5.15a4.36,4.36,0,0,0,.44,2,3,3,0,0,0,2.9,1.55c1.51,0,2.89-.52,3-3.22.07-1.33.12-3.44,0-5.21,0-1-.24-1.14-.77-1.24a7.18,7.18,0,0,0-.82-.14.53.53,0,0,1,0-.39c.31,0,1,0,2,0,.77,0,1.45,0,1.75,0a.53.53,0,0,1,0,.39l-.7.15c-.48.13-.58.42-.62,1.33a39.23,39.23,0,0,1-.29,6c-.44,2.47-2.28,3.22-4.19,3.22-2.67,0-3.92-1.21-4.16-3.17-.14-1.08-.11-5.34-.12-6.47,0-.46-.09-.77-.48-.9A4.74,4.74,0,0,0,8.26,87,.44.44,0,0,1,8.26,86.56Z"
            ></path>
            <path
              className="cls-5"
              d="M25.23,86.54c.41,0,1.26.06,2,.06.36,0,.66,0,.8,0a4.08,4.08,0,0,0,.65.94c1.8,2.26,5.51,6.78,6.32,7.74l0,0c0-2.34,0-5.93-.07-7.14,0-.52-.14-.8-.65-.94A6.62,6.62,0,0,0,33.23,87a.58.58,0,0,1,0-.41c.45,0,1.22.06,2.19.06.57,0,1.54-.06,1.75-.06s.05.35,0,.41a4.73,4.73,0,0,0-.72.15c-.47.14-.58.48-.6,1-.1,1.36-.08,4.18-.14,7,0,.8,0,1.8,0,2.36a10.46,10.46,0,0,0-1.12,0c-.89-1.29-5.95-7.59-6.88-8.75,0,0-.07,0-.07,0,0,1.78,0,6.07,0,7,.05.77.28,1,.7,1.11a8.66,8.66,0,0,0,1,.15c.07.06,0,.35,0,.41-.4,0-1.17-.06-2.1-.06s-1.58.06-1.95.06a.58.58,0,0,1,0-.41,3.52,3.52,0,0,0,.85-.15c.4-.11.64-.32.68-1.09s.12-3.88.16-7.51a1,1,0,0,0-.44-.85A2.45,2.45,0,0,0,25.22,87,.53.53,0,0,1,25.23,86.54Z"
            ></path>
            <path
              className="cls-5"
              d="M43.14,86.54c.38,0,1.31.06,2.2.06s1.63-.06,2-.06a.58.58,0,0,1,0,.41,7.48,7.48,0,0,0-.82.12c-.39.12-.46.35-.49.91s0,2.29,0,3.81c0,1.79,0,3.47,0,4.08s.08.81.56.95c.24.05.59.1,1,.15.07.06,0,.35,0,.41-.43,0-1.24-.06-2.08-.06-1,0-1.64.06-2.34.06a.46.46,0,0,1,0-.41,6.64,6.64,0,0,0,.82-.12c.47-.1.54-.35.59-.79s.06-2.71.06-4.65c0-1.44,0-2.64,0-3.47,0-.42-.07-.71-.45-.82a5.92,5.92,0,0,0-1-.17A.64.64,0,0,1,43.14,86.54Z"
            ></path>
            <path
              className="cls-5"
              d="M52.83,86.54c.4,0,1.33.06,2.08.06,1.05,0,1.82,0,2.14-.06.05.06.07.35,0,.41-.41.07-.65.14-.86.19s-.31.26-.23.51c.53,1.5,2.61,7,2.93,7.71H59c.35-.79,2.32-5.76,2.81-7.23.2-.61.14-.89-.36-1-.28,0-.53-.1-.93-.15a.64.64,0,0,1,.05-.41c.33,0,1,.06,1.94.06s1.4-.06,1.77-.06a.64.64,0,0,1,0,.41,3.68,3.68,0,0,0-.75.15c-.51.16-.68.49-1.12,1.54-.53,1.23-1.8,4.38-2.68,6.53-.37,1-.65,1.75-.8,2.29a2.9,2.9,0,0,0-.93,0c-.2-.68-.44-1.47-.79-2.38-.87-2.34-2-5.37-2.54-6.68-.38-.91-.57-1.19-1.07-1.31-.22-.06-.47-.09-.78-.14A.45.45,0,0,1,52.83,86.54Z"
            ></path>
            <path
              className="cls-5"
              d="M69.16,86.54c.42,0,1.21.06,2.17.06h3.39c.67,0,1.56,0,1.91-.06,0,0,.05.06.05.07A14.65,14.65,0,0,0,76.39,89,.62.62,0,0,1,76,89a6.14,6.14,0,0,0-.19-1.1c-.14-.4-.3-.61-.9-.66-.38,0-2.39,0-2.64,0s-.19.21-.19.29c0,.88,0,3.26,0,3.85,0,.07,0,.14.09.14.58,0,2.12,0,2.68,0a.69.69,0,0,0,.68-.56,6.34,6.34,0,0,0,.18-.67.68.68,0,0,1,.45,0A12.25,12.25,0,0,0,76,91.9c0,.66,0,1.15,0,1.54-.07.07-.4.09-.45,0a6.26,6.26,0,0,0-.14-.67.66.66,0,0,0-.65-.54c-.49,0-2.08,0-2.66,0,0,0-.09.1-.09.14,0,.66,0,2.73,0,3.65a.64.64,0,0,0,.4.65,19.17,19.17,0,0,0,2.38.11c.91,0,1.12-.2,1.4-.53a5.75,5.75,0,0,0,.93-1.57.68.68,0,0,1,.38.15,11.1,11.1,0,0,1-.73,2.33c-.11.19-.18.26-1.05.26-1.26,0-2.31-.07-4.43-.07-1.14,0-1.87.06-2.59.06a.49.49,0,0,1,0-.41A8.1,8.1,0,0,0,70,96.8c.4-.09.54-.3.57-1,0-.37,0-2.33,0-4.31,0-1.63,0-3.22,0-3.53,0-.65-.1-.81-.52-.91a5.44,5.44,0,0,0-.88-.16A.45.45,0,0,1,69.16,86.54Z"
            ></path>
            <path
              className="cls-5"
              d="M83.75,86.54c.4,0,1.4.06,1.93.06l2.81,0c2,0,3.07,1.17,3.07,2.66a3,3,0,0,1-2,2.78.09.09,0,0,0,0,.11,28.16,28.16,0,0,0,2.88,3.92,2.45,2.45,0,0,0,1.93.94s0,.37-.07.4a7.1,7.1,0,0,1-2.71-.31,2.24,2.24,0,0,1-.83-.68c-.66-.76-1.78-2.63-2.45-3.57a.5.5,0,0,0-.42-.28,11.41,11.41,0,0,0-1.17,0s-.07.07-.07.1c0,1,0,2.63,0,3.27s.15.81.52.9.56.1.95.15a.5.5,0,0,1,0,.41c-.31,0-1.19-.06-2.2-.06-1.18,0-1.86.06-2.16.06a.43.43,0,0,1,0-.41,7,7,0,0,0,.86-.14c.42-.08.54-.31.56-1,0-.45,0-2.9,0-3.87,0-1.5,0-3.51,0-4.13s-.14-.7-.56-.8a8.78,8.78,0,0,0-.89-.12C83.66,86.88,83.68,86.58,83.75,86.54Zm3,.62a.45.45,0,0,0-.1.24c0,.54,0,2.91,0,4.41,0,0,0,.11.07.11a6.43,6.43,0,0,0,2.19-.13,2,2,0,0,0,1.17-2.1,2.37,2.37,0,0,0-2.69-2.6A2.13,2.13,0,0,0,86.76,87.16Z"
            ></path>
            <path
              className="cls-5"
              d="M100.88,96.64a2.91,2.91,0,0,0,1.72.46,1.78,1.78,0,0,0,1.89-1.89c0-1.16-.74-1.75-2.28-2.68s-2.53-1.77-2.53-3.31c0-1.84,1.5-2.94,3.37-2.94a18.9,18.9,0,0,1,2.58.28,20,20,0,0,0-.3,2.36.51.51,0,0,1-.42,0c-.09-1.1-.26-1.57-.7-1.84a2.55,2.55,0,0,0-1.38-.33A1.77,1.77,0,0,0,101,88.59a2.25,2.25,0,0,0,1.17,2c.69.48,1.49.86,2.07,1.23a3.05,3.05,0,0,1,1.7,2.61c0,1.73-1.4,3.2-3.8,3.2a6,6,0,0,1-2.45-.53,14.09,14.09,0,0,1-.46-2.53.62.62,0,0,1,.44-.13A3.65,3.65,0,0,0,100.88,96.64Z"
            ></path>
            <path
              className="cls-5"
              d="M112.56,86.54c.38,0,1.31.06,2.2.06s1.63-.06,2-.06a.58.58,0,0,1,0,.41,7.48,7.48,0,0,0-.82.12c-.39.12-.46.35-.49.91s0,2.29,0,3.81c0,1.79,0,3.47,0,4.08s.08.81.56.95c.24.05.59.1,1,.15.07.06,0,.35,0,.41-.43,0-1.24-.06-2.08-.06-1,0-1.64.06-2.34.06a.46.46,0,0,1,0-.41,6.64,6.64,0,0,0,.82-.12c.47-.1.54-.35.59-.79s.06-2.71.06-4.65c0-1.44,0-2.64,0-3.47,0-.42-.07-.71-.45-.82a5.92,5.92,0,0,0-1-.17A.64.64,0,0,1,112.56,86.54Z"
            ></path>
            <path
              className="cls-5"
              d="M130.63,97.38c-.52,0-1.52-.06-2.41-.06-1.21,0-2.1.06-2.57.06a.46.46,0,0,1,0-.41c.52,0,.94-.1,1.19-.15.52-.11.61-.46.64-1.26,0-1.09,0-5.36,0-8.21a.13.13,0,0,0-.11-.12c-.31,0-1.8,0-2.32,0a.8.8,0,0,0-.72.46,9.74,9.74,0,0,0-.67,1.59s-.4,0-.42-.1c.13-.74.28-2.07.32-2.79,0,0,.12-.08.16-.05a3.31,3.31,0,0,0,1.38.25h6.46a4.06,4.06,0,0,0,1.62-.23c.06,0,.13,0,.14.07a17.42,17.42,0,0,0-.38,2.8.53.53,0,0,1-.46,0,6.36,6.36,0,0,0-.26-1.49.69.69,0,0,0-.68-.49c-.74-.05-2.12,0-2.5,0a.15.15,0,0,0-.09.14c0,2.54,0,7.46.07,8.37s.21.94.59,1.07a6.63,6.63,0,0,0,1,.15A.49.49,0,0,1,130.63,97.38Z"
            ></path>
            <path
              className="cls-5"
              d="M139.3,86.54c.47,0,1.31.06,2.15.06.63,0,1.63,0,1.85-.06a.43.43,0,0,1,0,.41,3.29,3.29,0,0,0-.61.12c-.25.07-.28.23-.12.52.45,1,1.89,3.71,2.24,4.33,0,0,.07,0,.08,0a46.22,46.22,0,0,0,2.19-4.1c.16-.38.12-.61-.37-.71A6.67,6.67,0,0,0,146,87a.42.42,0,0,1,0-.41c.49,0,1.26.06,1.86.06.77,0,1.19,0,1.8-.06.07,0,.07.35,0,.41l-.74.14c-.54.12-.79.45-1.19,1.08-.73,1.19-2.06,3.57-2.43,4.31a1.75,1.75,0,0,0-.14.59l0,2.61c0,.73.11,1.07.62,1.17.26.05.49.09.94.14a.57.57,0,0,1,0,.39c-.42,0-1.4-.06-2.33-.06-1.14,0-1.86.06-2.36.06-.07,0-.06-.34,0-.39a6.57,6.57,0,0,0,1.08-.16c.49-.1.56-.45.58-1l0-2.38a2.55,2.55,0,0,0-.14-.84c-.36-.86-1.41-2.82-2.1-4.05s-.87-1.36-1.43-1.5a3.67,3.67,0,0,0-.79-.12A.5.5,0,0,1,139.3,86.54Z"
            ></path>
          </svg>
        </div>

        <div className="western-info">
          <h2 className="visually-hidden">Western Contact Info</h2>
          <p>516 High Street, Bellingham, WA 98225</p>
          <p>
            <a href="tel:3606503000">(360) 650-3000</a> |&nbsp;
            <a href="https://www.wwu.edu/wwucontact/">Contact Western</a>
          </p>
          <h2 className="visually-hidden">Policy Links</h2>
          <ul className="social-media-links">
            <li>
              <a href="https://vu.wwu.edu/lost-and-found">
                Lost & Found Policy
              </a> |&nbsp;
            </li>
            <li>
              <a href="https://www.wwu.edu/privacy/">Privacy Policy</a> |&nbsp;
            </li>
            <li>
              <a href="https://www.wwu.edu/commitment-accessibility">
                Accessibility Notice
              </a>
            </li>
          </ul>
        </div>

        <h2 className="visually-hidden">Western social media links</h2>
        <ul className="social-media-links">
          <li>
            <a
              href="http://www.facebook.com/westernwashingtonuniversity"
              className="social-icon facebook"
            >
              <span className="visually-hidden">Western on Facebook</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36 36"
                aria-hidden="true"
                role="presentation"
              >
                <path d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"></path>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/westernwashingtonuniversity/"
              className="social-icon instagram"
            >
              <span className="visually-hidden">Western on Instagram</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36 36"
                aria-hidden="true"
                role="presentation"
              >
                <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"></path>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="http://www.youtube.com/wwu"
              className="social-icon youtube"
            >
              <span className="visually-hidden">Western on YouTube</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36 36"
                aria-hidden="true"
                role="presentation"
              >
                <path d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z"></path>
              </svg>
            </a>
          </li>
          <li>
            <a href="http://www.flickr.com/wwu" className="social-icon flickr">
              <span className="visually-hidden">Western on Flickr</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36 36"
                aria-hidden="true"
                role="presentation"
              >
                <path d="M6.7,16.4c-2.6,0-4.7-2.1-4.7-4.6c0-2.6,2.1-4.7,4.6-4.7c2.6,0,4.7,2.1,4.7,4.6c0,0,0,0,0,0C11.3,14.4,9.2,16.4,6.7,16.4z"></path>
                <path
                  d="M17.3,16.4c-2.6,0-4.7-2.1-4.7-4.6c0-2.6,2.1-4.7,4.6-4.7c2.6,0,4.7,2.1,4.7,4.6c0,0,0,0,0,0C22,14.4,19.9,16.4,17.3,16.4
                C17.3,16.4,17.3,16.4,17.3,16.4z"
                ></path>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://westerntoday.wwu.edu/"
              className="social-icon western-today"
            >
              <span className="visually-hidden">Western Today</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36 36"
                aria-hidden="true"
                role="presentation"
              >
                <polygon
                  points="11.8,17.5 9.4,9.5 7.1,17.5 5.2,17.5 2,6.4 4.1,6.4 6.2,14.1 8.5,6.4 10.4,6.4 12.7,14.1 14.9,6.4 16.9,6.4
                13.6,17.5 "
                ></polygon>
                <path
                  d="M21.8,17.4c-0.5,0.2-1,0.3-1.5,0.3c-2.1,0-2.2-1.4-2.2-2.6v-3.4h-1.5V9.9h1.5V8h1.8v1.9h2v1.7h-2v3.1c0,1,0.1,1.2,0.7,1.2
                c0.3,0,0.6-0.1,0.9-0.2l0.5-0.2l0,1.8L21.8,17.4z"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </section>
    </footer>
  );
}

export default Footer;
