import React from "react";
import MainMenu from "./MainMenu";
import WWULogo from "./components/WWULogo";
import Avatar from "react-avatar";

function Header() {
  let avatar = "";
  let user = localStorage.getItem("user");
  if (user !== null) {
    user = JSON.parse(user);
    avatar = (
      <>
        <Avatar name={`${user.first_name} ${user.last_name}`} round={true} />
        <div className="avatar-level">
          {user.is_superuser ? "Admin" : "Staff"}
        </div>
        <div className="avatar-department">{user.department}</div>
      </>
    );
  }
  return (
    <header
      className={`site-header ${window.location.pathname === "/" && "home"}`}
      role="banner"
    >
      <wwu-pre-header className="element-created visually-hidden">
        <a href="#main-content" className="skip-link focusable visually-hidden">
          <span className="component-icon" aria-hidden="true">
            <svg
              enableBackground="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
            >
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g>
                  <polygon points="18,6.41 16.59,5 12,9.58 7.41,5 6,6.41 12,12.41" />
                  <polygon points="18,13 16.59,11.59 12,16.17 7.41,11.59 6,13 12,19" />
                </g>
              </g>
            </svg>
          </span>
          <span className="skip-link-text">Skip to Content </span>
        </a>

        {/* <wwu-search></wwu-search> */}
        {/* <a href="#main-content" className="skip-link focusable icon-link">
          <span className="component-icon" aria-hidden="true">
            <svg
              enableBackground="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
            >
              <g>
                <rect fill="none" height="24" width="24"></rect>
              </g>
              <g>
                <g>
                  <polygon points="18,6.41 16.59,5 12,9.58 7.41,5 6,6.41 12,12.41"></polygon>
                  <polygon points="18,13 16.59,11.59 12,16.17 7.41,11.59 6,13 12,19"></polygon>
                </g>
              </g>
            </svg>
          </span>
          <span className="skip-link-text">Skip to Content </span>
        </a>

        <button
          className="toggle-settings"
          aria-expanded="false"
          aria-controls="settings-menu"
        >
          <span className="component-icon" aria-hidden="true">
            <svg
              enableBackground="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
            >
              <g>
                <path d="M0,0h24v24H0V0z" fill="none"></path>
                <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"></path>
              </g>
            </svg>
          </span>
          <span className="toggle-text">Display Settings</span>
        </button>

        <div id="settings-menu" className="settings-menu black-bg closed">
          <div className="menu-container">
            <fieldset className="theme-selection">
              <legend>Theme</legend>
              <div className="radio">
                <input
                  id="default-theme"
                  type="radio"
                  name="theme-select"
                  value="default-theme"
                  checked="checked"
                />
                <label htmlFor="default-theme">Default (System)</label>
              </div>
              <div className="radio">
                <input
                  id="dark-mode"
                  type="radio"
                  name="theme-select"
                  value="dark-mode"
                />
                <label htmlFor="dark-mode">Dark</label>
              </div>
              <div className="radio">
                <input
                  id="light-mode"
                  type="radio"
                  name="theme-select"
                  value="light-mode"
                />
                <label htmlFor="light-mode">Light</label>
              </div>
            </fieldset>

            <fieldset className="font-selection">
              <legend>Font</legend>
              <div className="radio">
                <input
                  id="font--default"
                  type="radio"
                  name="font-select"
                  value="font--default"
                  checked="checked"
                />
                <label htmlFor="font--default">Default</label>
              </div>
              <div className="radio">
                <input
                  id="font--serif"
                  type="radio"
                  name="font-select"
                  value="font--serif"
                />
                <label htmlFor="font--serif">Serif</label>
              </div>
              <div className="radio">
                <input
                  id="font--dyslexia-friendly"
                  type="radio"
                  name="font-select"
                  value="font--dyslexia-friendly"
                />
                <label htmlFor="font--dyslexia-friendly">Open Dyslexic</label>
              </div>
              <div className="radio">
                <input
                  id="font--hyperlegible"
                  type="radio"
                  name="font-select"
                  value="font--hyperlegible"
                />
                <label htmlFor="font--hyperlegible">Hyperlegible</label>
              </div>
            </fieldset>
          </div>
          <button className="reset-button">
            <span className="component-icon" aria-hidden="true">
              <svg height="24px" viewBox="0 0 24 24" width="24px">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"></path>
              </svg>
            </span>{" "}
            Reset preferences
          </button>
        </div>
        <wwu-search
          role="search"
          aria-label="Western"
          className="element-created"
        >
          <button
            className="toggle-settings"
            aria-expanded="false"
            aria-controls="search-menu"
          >
            <span className="component-icon" aria-hidden="true">
              <svg
                enableBackground="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#e8eaed"
              >
                <g>
                  <path d="M0,0h24v24H0V0z" fill="none"></path>
                </g>
                <g>
                  <path d="M7,9H2V7h5V9z M7,12H2v2h5V12z M20.59,19l-3.83-3.83C15.96,15.69,15.02,16,14,16c-2.76,0-5-2.24-5-5s2.24-5,5-5s5,2.24,5,5 c0,1.02-0.31,1.96-0.83,2.75L22,17.59L20.59,19z M17,11c0-1.65-1.35-3-3-3s-3,1.35-3,3s1.35,3,3,3S17,12.65,17,11z M2,19h10v-2H2 V19z"></path>
                </g>
              </svg>
            </span>
            <span className="toggle-text">Search Settings</span>
          </button>

          <div id="search-menu" className="search-menu black-bg closed">
            <fieldset className="search-selection">
              <legend>Search Scope</legend>
              <div className="radio">
                <input
                  id="site-search"
                  name="search-select"
                  value="site"
                  type="radio"
                  checked=""
                />
                <label htmlFor="site-search">
                  This department or website only
                </label>
              </div>

              <div className="radio">
                <input
                  id="full-search"
                  name="search-select"
                  value="full"
                  type="radio"
                />
                <label htmlFor="full-search">
                  All Western Washington University websites
                </label>
              </div>
            </fieldset>
          </div>

          <form className="search-area">
            <label htmlFor="search-box" className="search-label">
              Search:
            </label>
            <input id="search-box" name="q" type="search" />
            <button className="submit-search" type="submit">
              <span className="component-icon" aria-hidden="true">
                <svg height="24px" viewBox="0 0 24 24" width="24px">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                </svg>
              </span>
              <span className="toggle-text">Go</span>
            </button>
          </form>
        </wwu-search> */}
      </wwu-pre-header>
      <div className="western-avatar">{avatar}</div>
      <wwu-header
        sitename="Western Washington University"
        regioncontent=""
        className="element-created"
      >
        <noscript>
          <nav
            className="wwu-menu wwu-menu-closed"
            aria-label="Western Quick Links"
          >
            <ul>
              <li>
                <a
                  className="small icon-link apply-quick-link"
                  href="https://admissions.wwu.edu/apply"
                >
                  <span className="component-icon" aria-hidden="true">
                    <svg height="24px" viewBox="0 0 24 24" width="24px">
                      <path d="M0 0h24v24H0z" fill="none" fillRule="evenodd" />
                      <g fillRule="evenodd">
                        <path d="M9 17l3-2.94c-.39-.04-.68-.06-1-.06-2.67 0-8 1.34-8 4v2h9l-3-3zm2-5c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4" />
                        <path d="M15.47 20.5L12 17l1.4-1.41 2.07 2.08 5.13-5.17 1.4 1.41z" />
                      </g>
                    </svg>
                  </span>
                  Apply
                </a>
              </li>
              <li>
                <a
                  className="small icon-link give-quick-link"
                  href="https://alumniq.wwu.edu/giving/make-a-gift"
                >
                  <span className="component-icon" aria-hidden="true">
                    <svg height="24px" viewBox="0 0 24 24" width="24px">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
                    </svg>
                  </span>
                  Give
                </a>
              </li>
              <li>
                <a
                  className="small icon-link report-bias-quick-link"
                  href="https://www.wwu.edu/sebrt/report-bias-incident"
                >
                  <span className="component-icon" aria-hidden="true">
                    <svg height="24px" viewBox="0 0 24 24" width="24px">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z" />
                    </svg>
                  </span>
                  Report Bias
                </a>
              </li>
              <li>
                <a
                  className="small icon-link my-western-link"
                  href="https://mywestern.wwu.edu"
                >
                  <span className="component-icon" aria-hidden="true">
                    <svg height="24px" viewBox="0 0 24 24" width="24px">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 2c-4.97 0-9 4.03-9 9 0 4.17 2.84 7.67 6.69 8.69L12 22l2.31-2.31C18.16 18.67 21 15.17 21 11c0-4.97-4.03-9-9-9zm0 2c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.3c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
                    </svg>
                  </span>
                  myWestern
                </a>
              </li>
              <li>
                <a
                  className="small icon-link jobs-link"
                  href="https://www.wwu.edu/workatwestern"
                >
                  <span className="component-icon" aria-hidden="true">
                    <svg height="24px" viewBox="0 0 24 24" width="24px">
                      <path
                        d="M0 0h24v24H0zm10 5h4v2h-4zm0 0h4v2h-4z"
                        fill="none"
                      />
                      <path d="M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z" />
                    </svg>
                  </span>
                  Jobs
                </a>
              </li>
            </ul>
          </nav>
          <div className="site-info">
            <div className="western-logo">
              <a className="western-home-link" href="https://www.wwu.edu">
                <WWULogo />
              </a>
            </div>

            <div className="site-name">
              <a href="/" className="home-link">
                Western Washington University
              </a>
            </div>

            <div className="western-header-region"></div>
          </div>
        </noscript>

        <button className="small toggle-menu" aria-expanded="false">
          <span className="component-icon" aria-hidden="true">
            <svg height="24px" viewBox="0 0 24 24" width="24px">
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
            </svg>
          </span>
          <span className="toggle-text">Menu</span>
        </button>

        <nav
          className="wwu-menu wwu-menu-closed"
          aria-label="Western Quick Links"
        >
          <ul>
            <li>
              <a
                className="small icon-link apply-quick-link"
                href="https://admissions.wwu.edu/apply"
              >
                <span className="component-icon" aria-hidden="true">
                  <svg height="24px" viewBox="0 0 24 24" width="24px">
                    <path
                      d="M0 0h24v24H0z"
                      fill="none"
                      fillRule="evenodd"
                    ></path>
                    <g fillRule="evenodd">
                      <path d="M9 17l3-2.94c-.39-.04-.68-.06-1-.06-2.67 0-8 1.34-8 4v2h9l-3-3zm2-5c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4"></path>
                      <path d="M15.47 20.5L12 17l1.4-1.41 2.07 2.08 5.13-5.17 1.4 1.41z"></path>
                    </g>
                  </svg>
                </span>
                Apply
              </a>
            </li>
            <li>
              <a
                className="small icon-link give-quick-link"
                href="https://alumniq.wwu.edu/giving/make-a-gift"
              >
                <span className="component-icon" aria-hidden="true">
                  <svg height="24px" viewBox="0 0 24 24" width="24px">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"></path>
                  </svg>
                </span>
                Give
              </a>
            </li>
            <li>
              <a
                className="small icon-link report-bias-quick-link"
                href="https://www.wwu.edu/sebrt/report-bias-incident"
              >
                <span className="component-icon" aria-hidden="true">
                  <svg height="24px" viewBox="0 0 24 24" width="24px">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z"></path>
                  </svg>
                </span>
                Report Bias
              </a>
            </li>
            <li>
              <a
                className="small icon-link my-western-link"
                href="https://mywestern.wwu.edu"
              >
                <span className="component-icon" aria-hidden="true">
                  <svg height="24px" viewBox="0 0 24 24" width="24px">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 2c-4.97 0-9 4.03-9 9 0 4.17 2.84 7.67 6.69 8.69L12 22l2.31-2.31C18.16 18.67 21 15.17 21 11c0-4.97-4.03-9-9-9zm0 2c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.3c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
                  </svg>
                </span>
                myWestern
              </a>
            </li>
            <li>
              <a
                className="small icon-link jobs-link"
                href="https://www.wwu.edu/workatwestern"
              >
                <span className="component-icon" aria-hidden="true">
                  <svg height="24px" viewBox="0 0 24 24" width="24px">
                    <path
                      d="M0 0h24v24H0zm10 5h4v2h-4zm0 0h4v2h-4z"
                      fill="none"
                    ></path>
                    <path d="M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z"></path>
                  </svg>
                </span>
                Jobs
              </a>
            </li>
          </ul>
        </nav>

        <div className="site-info wwu-menu-open">
          <div className="western-logo">
            <a className="wwu-home-link" href="https://www.wwu.edu">
              <WWULogo />
            </a>
          </div>
          <div className="site-name">
            <a href="/" className="home-link">
              Western Washington University
            </a>
          </div>
          <div className="western-header-region"></div>
        </div>
      </wwu-header>
      <nav className="nav--main" aria-label="Main">
        <MainMenu />
      </nav>
    </header>
  );
}

export default Header;
