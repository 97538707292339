import React from "react";
import CurrencyInput from "react-currency-input-field";
import {
  FormControl,
  Button,
  Col,
  Image,
  Toast,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  fetchTicket,
  fetchFoundItem,
  fetchAllSubcategories,
  putFoundItem,
  postSale,
  postSaleItem,
  fetchSettings,
} from "./services/DataService";
import Scanner from "./components/Scanner";
import styles from "./style/sale.module.scss";

class Sale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: [],
      totalPrice: 0,
      variant: "",
      alertMessage: "",
      qr: "",
    };
  }

  componentDidMount() {
    Promise.all([
      fetchAllSubcategories(),
      fetchSettings(["name=PercentageDiscount"]),
    ]).then(([subcategories, percentageDiscount]) => {
      let subcategoryMap = {};
      subcategories.forEach((subcategory) => {
        subcategoryMap[subcategory["ID"]] = subcategory;
      });
      if (!percentageDiscount || percentageDiscount.length !== 1) {
        percentageDiscount = 0;
      } else {
        percentageDiscount = parseFloat(percentageDiscount[0].value);
      }
      this.setState({
        subcategories: subcategoryMap,
        percentageDiscount: percentageDiscount,
      });
    });
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        this.setState({ camera: true });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addItem = () => {
    if (!this.state.qr || this.state.qr == null) {
      return this.setState({
        variant: "bg-danger",
        alertMessage: "Please enter a Ticket",
      });
    }
    fetchTicket(this.state.qr).then((item) => {
      if (!item.ID) {
        return this.setState({
          variant: "bg-danger",
          alertMessage:
            "Ticket not found, please re-enter or scan the QR code or Ticket",
        });
      }
      fetchFoundItem(item.ID).then(this.foundItemCallback);
    });
  };

  foundItemCallback = (item) => {
    let variant = "bg-light";
    let alertMessage = "Item added to cart";
    let cartItems = this.state.cartItems;
    if (!item) {
      variant = "bg-danger";
      alertMessage = "Item not found";
    } else if (
      this.state.cartItems.some((cartItem) => cartItem.ID === item.ID)
    ) {
      variant = "bg-danger";
      alertMessage = "Item already in cart";
    } else {
      if (item.Price === 0) {
        // substitute subcategory price if no item price
        item.Price = this.state.subcategories[item.SubCatID].Price;
      }
      if (this.state.percentageDiscount) {
        item.Price =
          item.Price - item.Price * (this.state.percentageDiscount / 100);
      }
      cartItems = [...this.state.cartItems, item];
    }
    this.setState({
      variant: variant,
      alertMessage: alertMessage,
      qr: "",
      cartItems: cartItems,
    });
  };

  removeItem = (index) => {
    let cartItems = this.state.cartItems;
    cartItems.splice(index, 1);
    this.setState({
      variant: "bg-light",
      alertMessage: "Item removed from cart",
      cartItems: cartItems,
    });
  };

  updateSale = () => {
    let promises = [];
    let sale = {
      amount: parseFloat(
        this.state.cartItems.reduce(
          (sum, p) => sum + parseFloat(p.Price).toFixed(2),
          0.0
        )
      ),
    };
    postSale(sale).then((saleID) => {
      this.state.cartItems.forEach((item) => {
        let saleItem = {
          sale_id: saleID,
          item_id: item.ID,
          amount: parseFloat(parseFloat(item.Price).toFixed(2)),
        };
        promises.push(postSaleItem(saleItem));
      });
      Promise.all(promises).then((results) => {
        console.log("Posted sales item: ", results);
        this.updateFoundItems();
      });
    });
  };

  updateFoundItems() {
    let cartItems = [];
    let promises = [];
    this.state.cartItems.forEach((item) => {
      item.ResolutionID = 1;
      item.Price = parseFloat(parseFloat(item.Price).toFixed(2));
      promises.push(putFoundItem(item));
    });
    Promise.all(promises).then((results) => {
      results.forEach((result, i) => {
        if (!result || !result.message || result.message !== "updated") {
          cartItems.push(this.state.cartItems[i]);
        }
      });
      if (cartItems.length > 0) {
        return this.setState({
          variant: "bg-danger",
          alertMessage: `The following item(s) ${cartItems.toString()} have failed to checkout`,
          cartItems: cartItems,
        });
      }
      fetchSettings(["name=PercentageDiscount"]).then((percentageDiscount) => {
        if (!percentageDiscount || percentageDiscount.length !== 1) {
          percentageDiscount = 0;
        } else {
          percentageDiscount = parseFloat(percentageDiscount[0].value);
        }
        this.setState({
          variant: "bg-light",
          alertMessage: "The transaction has been completed",
          cartItems: [],
          cartItemsTicket: [],
          qr: "",
          percentageDiscount: percentageDiscount,
        });
      });
    });
  }

  render() {
    return (
      <div className={styles["rummage-sale"]}>
        <div className="form-title">Rummage Sale</div>
        <div className=" pl-4 pr-4 pt-3">
          <Toast
            className={`d-inline-block sale-toast m-1 ${this.state.variant}`}
            onClose={() => this.setState({ alertMessage: "" })}
            show={this.state.alertMessage !== ""}
            delay={5000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">WWU Sale</strong>
            </Toast.Header>
            <Toast.Body>{this.state.alertMessage}</Toast.Body>
          </Toast>

          <Row className="justify-content-center pt-4 pb-2">
            <Col sm="4"></Col>
            <Col sm="2">
              <FormControl
                type="text"
                placeholder="Ticket"
                value={this.state.qr}
                className="ticket-input"
                onChange={(e) => {
                  this.setState({ qr: e.target.value });
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.addItem();
                  }
                }}
              />
            </Col>
            <Col sm="4">
              <Button onClick={this.addItem}>Add Item</Button>
            </Col>
          </Row>
          {this.state.camera ? (
            <div className="d-flex justify-content-md-center px-2 pb-4">
              <Col className="qr-container" xs={6} md={4}>
                <Scanner
                  onDetected={(qr) => {
                    if (qr) {
                      this.setState({ qr: qr }, () => this.addItem());
                    }
                  }}
                />
              </Col>
            </div>
          ) : (
            ""
          )}
          {this.state.percentageDiscount ? (
            <Row className="discount justify-content-center pt-2 pb-2">
              {this.state.percentageDiscount}% discount in effect
            </Row>
          ) : (
            ""
          )}
          {this.state.cartItems.toString() ? (
            <div className="px-4">
              {this.state.refresh ? (
                <Spinner animation="grow" variant="primary" />
              ) : (
                this.state.cartItems.map((item, index) => (
                  <div key={index}>
                    <Row className="d-flex justify-content-md-center align-items-center">
                      {item.Image ? (
                        <Col sm="2">
                          <Image className="item-image" src={item.Image} />
                        </Col>
                      ) : (
                        <Col sm="2">
                          <Image
                            className="item-image"
                            src={require(`./images/subcategories/${item.SubCatID}.svg`)}
                          />
                        </Col>
                      )}
                      <Col sm="2">
                        {this.state.subcategories[item.SubCatID].Name}
                      </Col>
                      <Col sm="5">{item.Desc || "-"}</Col>
                      <Col sm="2" className="d-flex font-weight-bold">
                        <span className="dollar-sign">$</span>
                        <CurrencyInput
                          id="input-example"
                          name="input-name"
                          className="price-field"
                          value={item.Price}
                          defaultValue={0}
                          decimalsLimit={2}
                          onValueChange={(value, name) => {
                            let cart = this.state.cartItems;
                            cart[index].Price = value;
                            this.setState({ cartItems: cart });
                          }}
                        />
                      </Col>
                      <Col sm="1">
                        <Button
                          variant="danger"
                          className="delete-icon-button"
                          onClick={() => this.removeItem(index)}
                        >
                          <div className="glyphicon glyphicon-remove"></div>
                        </Button>
                      </Col>
                    </Row>
                    <hr />
                  </div>
                ))
              )}
              <Row className="d-flex pt-3 pb-4 pl-3 justify-content-center font-weight-bold large-text">
                <Col sm="12">
                  Total: $
                  {parseFloat(
                    this.state.cartItems.reduce(
                      (sum, p) => sum + parseFloat(p.Price).toFixed(2),
                      0.0
                    )
                  ).toFixed(2)}
                </Col>
              </Row>
              <Row className="d-flex pt-3 pb-4 pl-3 justify-content-center">
                <Col sm="4"></Col>
                <Col sm="4">
                  <Button
                    variant="success"
                    className="btn-submit"
                    onClick={this.updateSale}
                  >
                    Checkout
                  </Button>
                </Col>
                <Col sm="2"></Col>
                <Col sm="2">
                  <Button
                    variant="outline-danger"
                    onClick={() => {
                      this.setState({
                        cartItems: [],
                        cartItemsTicket: [],
                        variant: "bg-success",
                        alertMessage: "Transaction has been cancelled",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Sale;
