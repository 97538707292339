import React, { useState } from "react";
import { Table } from "react-bootstrap";

/**
 * MultiSelectTable component that allows the user to select multiple rows.
 * Sortable columns can be specified as an array of column indices.
 *
 * @param {Array} headers - An array of objects containing a label and dataKey representing the table headers.
 * @param {Array} data - An array of objects representing the table data.
 * @param {Array} [sortableColumns=[]] - An optional array of indices of the columns that are sortable.
 * @param {String} [className=""] - An optional string for the table class.
 *
 * @returns {React.Component} - A React component for a table that supports multiple row selection and sorting.
 */
const MultiSelectTable = ({
  headers,
  data,
  sortableColumns = [],
  onSelect = () => {},
  onDoubleClick = () => {},
  className = "",
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortAscending, setSortAscending] = useState(true);

  const handleRowClick = (index, event) => {
    // Avoids toggling selection when clicking on the checkbox
    if (event.target.type === "checkbox") {
      return;
    }
    toggleRowSelection(index);
  };

  const toggleRowSelection = (index) => {
    const newSelectedRows = [...selectedRows];
    const rowIndex = newSelectedRows.indexOf(index);
    if (rowIndex === -1) {
      newSelectedRows.push(index);
    } else {
      newSelectedRows.splice(rowIndex, 1);
    }
    setSelectedRows(newSelectedRows);
    onSelect(newSelectedRows.map((index) => data[index]));
  };

  const handleSelectAllClick = () => {
    const allSelected = selectedRows.length === data.length;
    const newSelectedRows = allSelected ? [] : data.map((_, index) => index);
    setSelectedRows(newSelectedRows);
    onSelect(newSelectedRows.map((index) => data[index]));
  };

  const handleSortClick = (header) => {
    if (sortColumn === header) {
      setSortAscending(!sortAscending);
    } else {
      setSortColumn(header);
      setSortAscending(true);
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortColumn === null) return 0;
    return (
      (sortAscending ? 1 : -1) *
      `${a[sortColumn]}`.localeCompare(`${b[sortColumn]}`)
    );
  });

  return (
    <Table striped bordered hover className={className}>
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              checked={selectedRows.length === data.length}
              onChange={handleSelectAllClick}
            />
          </th>
          {headers.map((header) => (
            <th
              key={header.dataKey}
              onClick={() =>
                sortableColumns.includes(header.dataKey) &&
                handleSortClick(header.dataKey)
              }
            >
              {header.label}{" "}
              {sortColumn === header.dataKey && (sortAscending ? "▲" : "▼")}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((row, index) => (
          <tr
            key={index}
            onClick={(e) => handleRowClick(index, e)}
            onDoubleClick={() => onDoubleClick(row)}
            className={selectedRows.includes(index) ? "selected" : ""}
          >
            <td>
              <input
                type="checkbox"
                checked={selectedRows.includes(index)}
                onChange={() => toggleRowSelection(index)}
              />
            </td>
            {headers.map((header) => (
              <td key={`${header.dataKey}-${index}`}>{row[header.dataKey]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MultiSelectTable;
