import React from "react";
import { Form, FormControl, Button, Row, Col, Container } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import Scanner from "./components/Scanner";
import Notification from "./components/Notification";
import { fetchTicket } from "./services/DataService";
import "./style/search.scss";
import "react-datepicker/dist/react-datepicker.css";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catID: "0",
      view: "card",
      dateBefore: "",
      dateAfter: "",
      redirect: false,
      showToast: false,
    };
    this.timeout = null;
    this.search = "";
    this.scannerRef = React.createRef();
  }

  onSearchChange = (event) => {
    this.search = event.target.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(
      () => this.props.onSearchChange(this.search),
      800
    );
  };

  onDetected = (result) => {
    if (!result || result === "") return;
    this.setState({
      scanning: !this.state.scanning,
      startScanner: this.state.scanning,
    });
    fetchTicket(result).then(this.ticketCallback);
  };

  ticketCallback = (item) => {
    if (!item) {
      this.setState({
        notification: "Item not found, please try again",
        showToast: true,
      });
    }
    if (item["Type"] === "lost") {
      return this.setState({ redirect: `/lost/${item["ID"]}` });
    }
    if (item["Type"] === "found") {
      return this.setState({ redirect: `/found/${item["ID"]}` });
    }
    this.setState({
      notification: "Bad scan, please try again",
      showToast: true,
    });
  };

  onViewChange = () => {
    if (this.state.view === "card") {
      this.setState({ view: "list" });
      this.props.onViewChange("list");
    } else {
      this.setState({ view: "card" });
      this.props.onViewChange("card");
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    let notification;
    if (this.state.showToast) {
      notification = (
        <Notification
          show={this.state.showToast}
          message={this.state.notification}
        ></Notification>
      );
    }

    const catID = this.state.catID;
    let subCategories;

    if (catID === "1") {
      subCategories = (
        <FormControl
          as="select"
          onChange={(e) => this.props.onSubcategoryChange(e.target.value)}
        >
          <option value="0" defaultValue>
            All
          </option>
          <option value="1">USB Drive</option>
          <option value="2">Cellphone</option>
          <option value="3">MP3 Player</option>
          <option value="4">Camera</option>
          <option value="32">Headphones</option>
          <option value="5">Other</option>
        </FormControl>
      );
    } else if (catID === "2") {
      subCategories = (
        <FormControl
          as="select"
          onChange={(e) => this.props.onSubcategoryChange(e.target.value)}
        >
          <option value="0" defaultValue>
            All
          </option>
          <option value="6">Book</option>
          <option value="7">DVD/Blueray</option>
          <option value="8">CD</option>
          <option value="9">Video Game</option>
          <option value="31">Other</option>
        </FormControl>
      );
    } else if (catID === "3") {
      subCategories = (
        <FormControl
          as="select"
          onChange={(e) => this.props.onSubcategoryChange(e.target.value)}
        >
          <option value="0" defaultValue>
            All
          </option>
          <option value="10">Hat</option>
          <option value="11">Scarf</option>
          <option value="12">Mittens/Gloves</option>
          <option value="13">Top/Shirt/Sweater</option>
          <option value="14">Bottoms/Pants/Shorts</option>
          <option value="15">Outerwear/Jackets</option>
          <option value="16">Shoes</option>
          <option value="17">Jewelry/Watch</option>
          <option value="18">Glasses</option>
          <option value="19">Other</option>
        </FormControl>
      );
    } else if (catID === "4") {
      subCategories = (
        <FormControl
          as="select"
          onChange={(e) => this.props.onSubcategoryChange(e.target.value)}
        >
          <option value="0" defaultValue>
            All
          </option>
          <option value="20">Keys</option>
          <option value="21">ID Card</option>
          <option value="22">Wallet</option>
          <option value="23">Credit/Debit Card</option>
          <option value="30">Other</option>
        </FormControl>
      );
    } else if (catID === "5") {
      subCategories = (
        <FormControl
          as="select"
          onChange={(e) => this.props.onSubcategoryChange(e.target.value)}
        >
          <option value="0" defaultValue>
            All
          </option>
          <option value="24">Waterbottle</option>
          <option value="25">Coffee Mug</option>
          <option value="28">Other</option>
        </FormControl>
      );
    } else if (catID === "6") {
      subCategories = (
        <FormControl
          as="select"
          onChange={(e) => this.props.onSubcategoryChange(e.target.value)}
        >
          <option value="0" defaultValue>
            All
          </option>
          <option value="24">Backpacks, Purses, Bags</option>
          <option value="29">Other</option>
        </FormControl>
      );
    }

    let resolutions;
    if (this.props.onResolutionChange) {
      resolutions = (
        <Col>
          <Form.Label>Resolution</Form.Label>
          <FormControl
            as="select"
            onChange={(event) =>
              this.props.onResolutionChange(parseInt(event.target.value))
            }
          >
            <option value={0} defaultValue>
              All
            </option>
            <option value={1}>Sold</option>
            <option value={2}>Returned</option>
            <option value={3}>Disposed</option>
            <option value={5}>Donated</option>
            <option value={4}>In Inventory</option>
          </FormControl>
        </Col>
      );
    }

    let location;
    if (this.props.onLocationChange) {
      location = (
        <Col>
          <Form.Label>Location</Form.Label>
          <FormControl
            as="select"
            onChange={(event) =>
              this.props.onLocationChange(parseInt(event.target.value))
            }
          >
            <option key={0} value={0} defaultValue>
              All
            </option>
            {Object.keys(this.props.locations).map((index) => {
              return (
                <option key={index} value={index}>
                  {this.props.locations[index]}
                </option>
              );
            })}
          </FormControl>
        </Col>
      );
    }

    let departments;
    if (this.props.onDepartmentChange) {
      departments = (
        <Col>
          <Form.Label>Dept</Form.Label>
          <FormControl
            as="select"
            onChange={(event) =>
              this.setState({ departmentID: event.target.value }, () =>
                this.props.onDepartmentChange(parseInt(event.target.value))
              )
            }
          >
            <option key={0} value={0} defaultValue>
              All
            </option>
            {this.props.departments.map((d) => {
              return (
                <option key={d.ID} value={d.ID}>
                  {d.Name}
                </option>
              );
            })}
          </FormControl>
        </Col>
      );
    }

    return (
      <Container>
        <Row
          className={`search-bar d-flex align-items-end  ${
            this.props.className ? this.props.className : ""
          }`}
        >
          {notification}

          <Col>
            <FormControl
              type="text"
              placeholder="Search"
              autoComplete="off"
              className="mr-sm-2 search-input"
              onChange={this.onSearchChange}
            />
          </Col>

          <Col>
            <Form.Label>Category</Form.Label>
            <FormControl
              as="select"
              onChange={(event) => {
                this.setState({ catID: event.target.value }, () =>
                  this.props.onCategoryChange(event.target.value)
                );
              }}
            >
              <option value="0" defaultValue>
                All
              </option>
              <option value="1">Electronics</option>
              <option value="2">Media</option>
              <option value="3">Clothing</option>
              <option value="4">Personal Items</option>
              <option value="5">Waterbottle</option>
              <option value="6">Bags</option>
              <option value="7">Other</option>
            </FormControl>
          </Col>

          {subCategories ? (
            <Col>
              <Form.Label>Subcategories</Form.Label>
              {subCategories}
            </Col>
          ) : (
            ""
          )}
          {resolutions}
          {departments}
          {location}

          <Col md="auto">
            <Row className="date-label">
              <Form.Label>Date</Form.Label>
            </Row>
            <Row className="date-row">
              <Col md="auto">
                <DatePicker
                  className="form-control date-control"
                  name="date-after"
                  selected={this.state.dateAfter}
                  onChange={(date) => {
                    this.setState({ dateAfter: date }, () =>
                      this.props.onAfterDateChange(date)
                    );
                  }}
                />
              </Col>
              <Col md="auto" className="date-search-dash">
                <div>-</div>
              </Col>
              <Col md="auto">
                <DatePicker
                  className="form-control date-control"
                  name="date-before"
                  selected={this.state.dateBefore}
                  onChange={(date) => {
                    this.setState({ dateBefore: date }, () =>
                      this.props.onBeforeDateChange(date)
                    );
                  }}
                />
              </Col>
            </Row>
          </Col>

          <Col md="auto">
            <Row className="btn-row">
              {this.props.addAction ? (
                <Col className="btn-col">
                  <Button
                    variant="primary"
                    href={this.props.addAction}
                    className="glyphicon glyphicon-plus btn-circle btn-add"
                  >
                    <div className="hidden">Add</div>
                  </Button>
                </Col>
              ) : null}
              {this.props.onViewChange ? (
                <Col className="btn-col">
                  <Button
                    variant="primary"
                    onClick={this.onViewChange}
                    className={`glyphicon ${
                      this.state.view === "card"
                        ? "glyphicon-list"
                        : "glyphicon-th-large"
                    } btn-view`}
                  >
                    <div className="hidden">View</div>
                  </Button>
                </Col>
              ) : null}
              <Col className="btn-col">
                <Button
                  variant="primary"
                  onClick={() =>
                    this.setState({
                      scanning: !this.state.scanning,
                      startScanner: !this.state.scanning,
                    })
                  }
                  className="glyphicon glyphicon-qrcode btn-barcode"
                >
                  <div className="hidden">Barcode</div>
                </Button>
              </Col>
            </Row>
          </Col>
          {this.state.scanning ? (
            <div ref={this.scannerRef} className="barcode-preview">
              <canvas
                className="drawingBuffer"
                style={{
                  position: "absolute",
                  top: "0px",
                }}
                width="640"
                height="480"
              />
              {this.state.startScanner ? (
                <Scanner
                  scannerRef={this.scannerRef}
                  onDetected={this.onDetected}
                  onClose={() =>
                    this.setState({
                      scanning: !this.state.scanning,
                      startScanner: !this.state.scanning,
                    })
                  }
                />
              ) : null}
            </div>
          ) : null}
        </Row>
      </Container>
    );
  }
}

export default Search;
