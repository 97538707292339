import React from "react";
import { Card } from "react-bootstrap";

class FoundCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: false,
    };
  }

  render() {
    let colors = [];
    let titleString = this.props.item.Attr.substr(1)
      .split("_")
      .map((attr) => {
        let parts = attr.split("=");
        if (["Primary Color", "Secondary Color"].includes(parts[0])) {
          colors.push(
            <div
              className={parts[0]}
              key={parts[0]}
              title={parts[1]}
              style={{ backgroundColor: parts[1] }}
            ></div>
          );
          return "";
        }
        return parts.pop();
      })
      .join(" ");
    let imgSrc = `${process.env.REACT_APP_BLOB_URL}/images/found/${this.props.item.ID}`;
    let imageFallbackSrc = require("../images/subcategories/" +
      this.props.item.SubCatID +
      ".svg");
    const imgClass = "card-image card-img-top";
    let resolutionControl;
    if (this.props.onClickResolutionChange) {
      resolutionControl = (
        <div
          className="card-delete"
          name={this.props.item.ID}
          onClick={this.props.onClickResolutionChange}
        >
          <div
            className="glyphicon glyphicon-remove"
            name={this.props.item.ID}
          ></div>
        </div>
      );
    }
    const department = this.props.department ? (
      <>
        <div className="glyphicon glyphicon-map-marker"></div>&nbsp;
        {this.props.department}
      </>
    ) : (
      ""
    );
    return (
      <Card onClick={this.props.onClick} key={this.props.item.ID}>
        {resolutionControl}
        <Card.Img
          variant="top"
          className={imgClass}
          src={imgSrc}
          onError={(e) => {
            e.target.onerror = null;
            e.target.classList.add("card-svg");
            e.target.src = imageFallbackSrc;
          }}
          loading="lazy"
        />
        {colors.map((color) => {
          return color;
        })}
        <Card.Body>
          <Card.Title>{titleString}</Card.Title>
          <Card.Text>{this.props.item.Desc}</Card.Text>
          <div className="when-where">
            {department}
            <br />
            <div className="glyphicon glyphicon-calendar"></div>&nbsp;
            {this.props.item.When}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default FoundCard;
