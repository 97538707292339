import React from "react";
import { FormControl } from "react-bootstrap";

class CategorySelect extends React.Component {
  constructor(props) {
    super(props);
    this.onCategoryChange = this.onCategoryChange.bind(this);
  }

  onCategoryChange(event) {
    this.props.onCategoryChange(event.target.value);
  }

  render() {
    return (
      <FormControl
        required
        as="select"
        name="CatID"
        id="CatID"
        onChange={this.onCategoryChange}
        value={this.props.selected}
        disabled={this.props.categories.length === 0}
      >
        <option value="0" key="0">
          Select a category
        </option>
        {this.props.categories.map(category => {
          return (
            <option value={category.ID} key={category.ID}>
              {category.Name}
            </option>
          );
        })}
      </FormControl>
    );
  }
}

export default CategorySelect;
