import jwt_decode from "jwt-decode";
import { appInsights } from "./InsightsService";
import msalInstance, { loginSilentRequest } from "./AuthConfig";

const fetchSelf = () => fetchWithAuthPromise("GET", "/user");
const fetchUsers = () => fetchWithAuthPromise("GET", "/users");
const fetchSettings = (params) => {
  let url = "/setting";
  if (params && params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithAuthPromise("GET", url);
};
const fetchLost = (params) => {
  let url = "/lost";
  if (params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithAuthPromise("GET", url);
};
const fetchFound = (params) => {
  let url = "/found";
  if (params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithAuthPromise("GET", url);
};
const fetchReport = (params) => {
  let url = "/report";
  if (params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithAuthPromise("GET", url);
};
const fetchSetting = (id) => fetchWithAuthPromise("GET", `/setting/${id}`);
const fetchLostItem = (id) => fetchWithAuthPromise("GET", `/lost/${id}`);
const fetchFoundItem = (id) => fetchWithAuthPromise("GET", `/found/${id}`);
const fetchFoundImage = (id) =>
  fetchWithAuthPromise("GET", `/foundimage/${id}`);
const fetchTicket = (id) => fetchWithAuthPromise("GET", `/ticket/${id}`);
const fetchDepartments = () => fetchWithoutAuthPromise("GET", `/departments`);
const fetchCategories = () => fetchWithoutAuthPromise("GET", `/categories`);
const fetchSubcategories = (categoryID) => {
  let url = `/categories/${categoryID}/subcategories`;
  if (categoryID === "0") {
    url = `/subcategories`;
  }
  return fetchWithoutAuthPromise("GET", url);
};
const fetchAttributes = (subcategoryID) => {
  if (subcategoryID === "0") {
    return new Promise((r) => {
      r([]);
    });
  }
  return fetchWithoutAuthPromise(
    "GET",
    `/subcategories/${subcategoryID}/attributes`
  );
};
const fetchLocations = () => fetchWithAuthPromise("GET", `/locations`);
const fetchLostMatches = (id, callback) =>
  fetchWithAuthPromise("GET", `/lost/${id}/match`);
const fetchFoundMatches = (id) =>
  fetchWithAuthPromise("GET", `/found/${id}/match`);
const fetchAllSubcategories = () =>
  fetchWithoutAuthPromise("GET", `/subcategories`);
const fetchSales = (params) => {
  let url = "/sales";
  if (params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithoutAuthPromise("GET", url);
};
const fetchSaleItems = (saleID) =>
  fetchWithoutAuthPromise("GET", `/sale/${saleID}/saleitems`);
const fetchSalesReport = (params) => {
  let url = "/reportsale";
  if (params.length !== 0) {
    url += `?${params.join("&")}`;
  }
  return fetchWithoutAuthPromise("GET", url);
};

const putSubcategories = (item) =>
  fetchWithAuthPromise("PUT", `/subcategories`, item);
const putUser = (user) => fetchWithAuthPromise("PUT", `/users`, user);
const putSetting = (setting) =>
  fetchWithAuthPromise("PUT", `/setting`, setting);
const putLostItem = (item) => fetchWithAuthPromise("PUT", `/lost`, item);
const putFoundItem = (item) => fetchWithAuthPromise("PUT", `/found`, item);
const putFoundImage = (image) =>
  fetchWithAuthPromise("PUT", `/foundimage`, image);

const postUser = (user) => fetchWithAuthPromise("POST", `/users`, user);
const postSetting = (setting) =>
  fetchWithAuthPromise("POST", `/setting`, setting);
const postLostItem = (item, callback) =>
  fetchWithoutAuth("POST", `/lost?notify=true`, callback, item);
const postFoundItem = (item, onError = _errorHandler) =>
  fetchWithAuthPromise("POST", `/found`, item, onError);
const postSale = (item) => fetchWithAuthPromise("POST", `/sale`, item);
const postSaleItem = (item) =>
  fetchWithAuthPromise("POST", `/sale/${item.sale_id}/saleitem`, item);

const deleteUser = (id) => fetchWithAuthPromise("DELETE", `/users/${id}`);
const deleteSetting = (id) => fetchWithAuthPromise("DELETE", `/setting/${id}`);
const deleteLostItem = (id) => fetchWithAuthPromise("DELETE", `/lost/${id}`);
const deleteFoundItem = (id) => fetchWithAuthPromise("DELETE", `/found/${id}`);
const deleteFoundImage = (id) =>
  fetchWithAuthPromise("DELETE", `/foundimage/${id}`);

const bulkUpdate = (target, field, value, ids, callback) => {
  fetchWithAuth("PUT", `/bulk`, callback, {
    target: target,
    field: field,
    value: value,
    ids: ids,
  });
};

const sendEmail = (msg, subject, to, html, callback) => {
  fetchWithAuth("POST", `/email`, callback, {
    msg: msg,
    subject: subject,
    to: to,
    html: html,
  });
};

const isTokenExpired = (token) => {
  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000; // Convert to Unix timestamp
  const bufferTime = 60; // 60 seconds before actual expiration
  return decoded.exp ? decoded.exp - bufferTime < currentTime : true;
};

// Function to silently get a new token
const getTokenSilently = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    throw new Error("No accounts found in MSAL instance.");
  }
  const silentRequest = {
    scopes: loginSilentRequest.scopes,
    account: accounts[0],
  };

  try {
    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response.accessToken;
  } catch (error) {
    console.error("Token renewal failed:", error);
    // Optionally trigger a user interaction to log in again
    throw error;
  }
};

const fetchWithoutAuth = (method, url, callback, body = false) => {
  let options = {
    method: method,
  };
  if (body) {
    options["body"] = JSON.stringify(body);
  }
  fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
    .then((response) => {
      if (response.status === 204) {
        return;
      }
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchWithoutAuthPromise = (method, url, body = false) => {
  let options = {
    method: method,
  };
  if (body) {
    options["body"] = JSON.stringify(body);
  }
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
    .then((response) => {
      if (response.status === 204) {
        return;
      }
      if (response.ok) {
        return response.json();
      }
      if (response.status === 400) {
        return response.json();
      }
      console.log(response);
      throw new Error("Network response failed.");
    })
    .catch(_errorHandler);
};

const fetchWithAuth = (method, url, callback, body = false) => {
  let access_token = window.sessionStorage.getItem("access_token");

  if (!access_token || isTokenExpired(access_token)) {
    // console.log("Token Unset or Expired " + access_token);
    access_token = getTokenSilently(); // Function to handle token renewal
    window.sessionStorage.setItem("access_token", access_token);
  }

  let options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  };
  
  if (body) {
    options["body"] = JSON.stringify(body);
  }
  fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
    .then((response) => {
      if (response.status === 204) {
        return;
      }
      if (response.ok) {
        return response.json();
      }
      console.log(response);
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

const fetchWithAuthPromise = async (
  method,
  url,
  body = false,
  onError = _errorHandler
) => {
  try {
    let access_token = window.sessionStorage.getItem("access_token");

    if (!access_token || isTokenExpired(access_token)) {
      // console.log("Token Unset or Expired " + access_token);
      access_token = await getTokenSilently(); // Function to handle token renewal
      window.sessionStorage.setItem("access_token", access_token);
    }

    let options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };
    if (body) {
      options["body"] = JSON.stringify(body);
    }
    return fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
      .then((response) => {
        if (response.status === 204) {
          return;
        }
        if (response.ok) {
          return response.json();
        }
        if (response.status === 400) {
          console.log(response, response.json());
          throw new Error("Bad request.");
        }
        if (response.status === 401) {
          return response.json().then((e) => {
            console.log(e);
            if (e.error === "expired token") {
              let message =
                "Looks like your session has expired, please log back in to renew.";
              window.localStorage.clear();
              window.location.href = `/warning/${encodeURIComponent(
                message
              ).replace(/\./g, "%2E")}`;
            } else {
              console.log(response, response.json());
              throw new Error(e.error);
            }
          });
        }
        console.log(response, response.json());
        throw new Error("Network response failed.");
      })
      .catch(onError);
  } catch (error) {
    onError(error);
  }
};

let _errorHandler = (error) => {
  appInsights.trackException({ exception: error });
  console.error(error);
  if (
    (error.name === "TypeError" &&
      [
        "Failed to fetch",
        "NetworkError when attempting to fetch resource.",
      ].includes(error.message)) ||
    (error.name === "DOMException" &&
      ["The operation was aborted."].includes(error.message))
  ) {
    return;
  }

  let message =
    "There seems to be an issue with your token, please log back in and try again";
  if (error.message === "invalid user") {
    message =
      "Unauthorized user. If you are a Lost Found employee and require a login, please contact vu.its@wwu.edu";
  } else if (error.message === "invalid configuration") {
    message =
      "Oh no, there seems to be an issue with the authentication server. Please contact vu.its@wwu.edu";
  } else if (error.message === "invalid claims") {
    message =
      "Oh no, there seems to be an issue with the auth provider. Please contact vu.its@wwu.edu";
  } else if (error.message === "expired token") {
    message = "Your session has expired, please log back in to renew.";
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = `/warning/${encodeURIComponent(message).replace(
      /\./g,
      "%2E"
    )}`;
  } else {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = `/error/${encodeURIComponent(message).replace(
      /\./g,
      "%2E"
    )}`;
  }
};

export {
  fetchSelf,
  fetchSettings,
  fetchSetting,
  fetchUsers,
  fetchLost,
  fetchFound,
  fetchFoundImage,
  fetchReport,
  fetchLostItem,
  fetchFoundItem,
  fetchTicket,
  fetchDepartments,
  fetchCategories,
  fetchSubcategories,
  fetchAttributes,
  fetchLocations,
  fetchLostMatches,
  fetchFoundMatches,
  fetchAllSubcategories,
  fetchSalesReport,
  fetchSales,
  fetchSaleItems,
  putLostItem,
  putFoundItem,
  putFoundImage,
  putUser,
  putSetting,
  putSubcategories,
  postLostItem,
  postFoundItem,
  postUser,
  postSetting,
  postSale,
  postSaleItem,
  deleteUser,
  deleteSetting,
  deleteLostItem,
  deleteFoundItem,
  deleteFoundImage,
  bulkUpdate,
  sendEmail,
};
