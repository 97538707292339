import React from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Search from "./Search";
import { stringToDate } from "./services/UtilityService";
import {
  fetchFound,
  putFoundItem,
  fetchDepartments,
  fetchCategories,
  fetchSubcategories,
  fetchLocations,
} from "./services/DataService";
import { AttributesFormatter } from "./components/formatters/Formatters";
import FoundCard from "./components/FoundCard";
import ResolutionSelect from "./components/ResolutionSelect";
import LoadMask from "./components/LoadMask";
import Masonry from "react-masonry-component";

const masonryOptions = {
  transitionDuration: 0,
  itemSelector: ".card",
  columnWidth: ".card",
  percentPosition: true,
};

class Found extends React.Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    if (user !== null) {
      user = JSON.parse(user);
    }
    this.state = {
      loading: true,
      items: [],
      filtered: [],
      categories: {},
      subcategories: {},
      catID: 0,
      subCatID: 0,
      searchTerms: [],
      departments: [],
      departmentsByID: {},
      beforeDate: false,
      afterDate: false,
      maxResults: 50,
      alert: false,
      selected: 0,
      view: "card",
      redirect: false,
      user: user,
    };
    this.catID = "0";
    this.subCatID = "0";
    this.departmentID = "0";
  }

  componentDidMount() {
    Promise.all([
      fetchCategories(),
      fetchSubcategories("0"),
      fetchLocations(),
      fetchDepartments(),
    ])
      .then(([categories, subcategories, locations, departments]) => {
        let categoryMap = {};
        categories.forEach((category) => {
          categoryMap[category["ID"]] = category["Name"];
        });
        let subcategoryMap = {};
        subcategories.forEach((subcategory) => {
          subcategoryMap[subcategory["ID"]] = subcategory["Name"];
        });
        let locationMap = {};
        locations.forEach((location) => {
          locationMap[location["ID"]] = location["Name"];
        });
        let departmentsByID = {};
        departments.forEach((department) => {
          departmentsByID[department.ID] = department.Name;
        });
        this.setState({
          categories: categoryMap,
          subcategories: subcategoryMap,
          locations: locationMap,
          departments: departments,
          departmentsByID: departmentsByID,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
    this.getFound();
  }

  resolutionChangeCallback = (resolution) => {
    let selectedItem;
    this.state.items.forEach((item) => {
      if (item.ID === this.state.selected) {
        selectedItem = item;
      }
    });
    if (selectedItem) {
      selectedItem.ResolutionID = resolution;
      putFoundItem(selectedItem).then(this.updateResolutionCallback);
    } else {
      this.updateResolutionCallback();
    }
  };

  updateResolutionCallback = () => {
    this.setState({ alert: false, selected: 0 }, this.getFound());
  };

  getFound = () => {
    let params = [];
    if (this.catID !== "0") {
      params.push("category=" + this.catID);
    }
    if (this.subCatID !== "0") {
      params.push("subcategory=" + this.subCatID);
    }
    if (this.departmentID !== "0") {
      params.push("department=" + this.departmentID);
    }
    fetchFound(params).then((items) => {
      this.setState({ items: items }, this.applyFilters);
    });
  };

  applyFilters() {
    let filtered = this.state.items;
    if (this.state.beforeDate) {
      filtered = filtered.filter(
        (item) => stringToDate(item.When) <= this.state.beforeDate
      );
    }
    if (this.state.afterDate) {
      filtered = filtered.filter(
        (item) => stringToDate(item.When) >= this.state.afterDate
      );
    }
    this.state.searchTerms.map((term) => {
      return (filtered = filtered.filter((item) =>
        `${this.state.categories[
          item.CatID
        ].toLowerCase()} ${this.state.subcategories[
          item.SubCatID
        ].toLowerCase()} ${item.Desc.toLowerCase()} ${item.Where.toLowerCase()} ${item.Attr.toLowerCase()} ${item.Ticket.toLowerCase()}`.includes(
          term
        )
      ));
    });
    if (filtered) {
      this.setState({ filtered: filtered.slice(0, this.state.maxResults) });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    if (this.state.loading) {
      return <LoadMask />;
    }

    let alert;
    if (this.state.alert) {
      alert = (
        <Alert className="resolutionAlert" variant="primary">
          <Alert.Heading>Update Found Item Resolution</Alert.Heading>
          <hr />
          <div className="d-flex justify-content-end">
            <ResolutionSelect
              onResolutionChange={this.resolutionChangeCallback}
            ></ResolutionSelect>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              style={{ marginRight: "1em", marginTop: "2em" }}
              onClick={() => this.setState({ alert: false, selected: 0 })}
              variant="primary"
            >
              Cancel
            </Button>
          </div>
        </Alert>
      );
    }
    let search = (
      <Search
        className="mb-4"
        addAction="/found/add"
        onCategoryChange={(newCategory) => {
          this.catID = newCategory;
          this.subCatID = "0";
          this.getFound();
        }}
        onSubcategoryChange={(newCategory) => {
          this.subCatID = newCategory;
          this.getFound();
        }}
        onSearchChange={(terms) => {
          this.setState(
            { searchTerms: terms.toLowerCase().split(" ") },
            this.applyFilters
          );
        }}
        onViewChange={(view) => this.setState({ view: view })}
        onBeforeDateChange={(date) =>
          this.setState({ beforeDate: date }, this.applyFilters)
        }
        onAfterDateChange={(date) =>
          this.setState({ afterDate: date }, this.applyFilters)
        }
        onDepartmentChange={(newDepartment) => {
          this.departmentID = newDepartment;
          this.getFound();
        }}
        departments={this.state.departments}
      />
    );
    if (this.state.view === "list") {
      return (
        <div>
          {alert}
          {search}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="ticket-column">Ticket</th>
                <th>Attributes</th>
                <th className="where-found-column">Where Found</th>
                <th className="when-found-column">When Found</th>
              </tr>
            </thead>
            <tbody>
              {this.state.filtered.map((found) => {
                return (
                  <tr
                    key={found.ID}
                    onClick={() =>
                      this.setState({ redirect: `/found/${found.ID}` })
                    }
                  >
                    <td>{found.Ticket}</td>
                    <td>
                      <AttributesFormatter attr={found.Attr} />
                    </td>
                    <td>{found.Where}</td>
                    <td>{found.When}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <div className="list-message-bottom">
            Showing a limit of {this.state.maxResults} results
            <Button
              style={{ marginLeft: "1em" }}
              onClick={() => {
                this.setState({ maxResults: this.state.maxResults * 2 });
                this.getFound();
              }}
              variant="primary"
            >
              Load More
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div>
        {alert}
        {search}
        <Masonry options={masonryOptions}>
          {this.state.filtered.map((item) => {
            return this.state.user !== null &&
              this.state.user.department_id === item.DepartmentID ? (
              <FoundCard
                key={item.ID}
                item={item}
                department={this.state.departmentsByID[item.DepartmentID]}
                onClick={() => this.setState({ redirect: `/found/${item.ID}` })}
                onClickResolutionChange={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.setState({
                    alert: true,
                    selected: parseInt(event.target.getAttribute("name")),
                  });
                }}
              />
            ) : (
              <FoundCard
                key={item.ID}
                item={item}
                department={this.state.departmentsByID[item.DepartmentID]}
                onClick={() => this.setState({ redirect: `/found/${item.ID}` })}
              />
            );
          })}
        </Masonry>
        <div className="list-message-bottom">
          Showing a limit of {this.state.maxResults} results
          <Button
            style={{ marginLeft: "1em" }}
            onClick={() => {
              this.setState({ maxResults: this.state.maxResults * 2 });
              this.getFound();
            }}
            variant="primary"
          >
            Load More
          </Button>
        </div>
      </div>
    );
  }
}

export default Found;
