import { PublicClientApplication } from "@azure/msal-browser";

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID || "",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_APPID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin,
    postLogoutRedirectUri:
      process.env.REACT_APP_REDIRECT_URI || "https://lostfound.wwu.edu",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const loginPopupRequest = {
  scopes: ["openid", "profile", "User.Read"]
};

const loginSilentRequest = {
  scopes: ["openid", "profile", "User.Read"],
  prompt: "none", // Uses SSO if possible
};

const msalInstance = new PublicClientApplication(configuration);

export default msalInstance;
export { loginPopupRequest, loginSilentRequest, configuration };
