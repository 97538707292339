import React from "react";

class WWULogo extends React.Component {
  render() {
    return (
      <div className="wwu-logo make-waves">
        <span className="visually-hidden">
          Western Washington University - Make Waves.
        </span>
        <svg
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 576 537.7"
          aria-hidden="true"
        >
          <title>Western Logo</title>
          <rect y="0" fill="#003F87" width="576" height="537.7"></rect>
          <path
            fill="#FFFFFF"
            d="M38.8,172.5c5.6,0,10.2,0.6,17.7,0.6c8-0.1,11.9-0.3,15.2-0.4c0.3,0.3,0.3,1.9,0,2.2
      c-3.6,0.7-4.6,0.9-5.9,1.4c-1.9,0.9-2.4,2.4-1.4,6.2c3.4,14.2,12.2,44.1,17.1,60.3c0,0,0.3-0.1,0.4-0.3
      c5-13.9,19.7-55.4,22.4-66.1c1,0.1,4.5,0,5.3-0.1c2.6,10.9,18.5,55.3,23.1,66.9c0.1,0,0.4-0.1,0.6-0.1
      c3.6-11.3,13.9-45.4,16.4-57.3c0.9-4.4,1.2-8.2-2.6-9.3c-2.3-0.6-4-1-8-1.6c-0.3-0.4-0.4-1.9,0-2.2c3,0,9.9,0.4,15.5,0.4
      c7.8,0,11.5-0.3,14.4-0.4c0.4,0.3,0.4,1.9,0,2.2c-3.4,0.7-5.5,1.1-6.9,1.7c-3,1.4-4.5,3.6-6.5,9.3
      c-5.7,16.5-19.4,61.2-23.4,75.8c-0.7,0-4.6-0.1-5.5,0.1c-3.6-12.6-16.5-49.4-23.1-67.5c-0.1,0-0.4,0-0.6,0.3
      c-5.2,14.9-19.4,55-23,66.9c-0.6-0.1-5-0.1-5.9,0.1c-4.2-17.5-16.5-61.6-21.3-75.1c-2.4-7.5-3.9-9.3-7.9-10.5
      c-2.3-0.6-3.7-0.9-6.5-1.3C38.2,174.5,38.2,172.7,38.8,172.5z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M158.7,197.9c2.4,0,8.2,0.4,13.8,0.4H194c5.9,0,9.3-0.1,11.4-0.6c0,0,0.4,0.3,0.4,0.4
      c-1,3.2-2,10.2-2.2,14.8c-0.3,0.3-1.9,0.3-2.2,0c-0.3-2.3-0.7-5.2-1.3-6.6c-0.7-2.9-1.4-4.2-5.3-4.5c-2.6-0.3-13.9-0.1-15.4,0
      c-0.7,0.1-1.1,1.4-1.1,1.9c-0.1,7-0.3,19.8-0.1,22.1c0,0.4,0.3,0.7,0.6,0.7c4.7,0,12.2,0,15.2-0.1c3.2-0.1,3.7-2.2,4.5-4.2
      c0.4-1.3,0.7-2.7,0.9-4c0.3-0.1,2.2-0.1,2.3,0.1c-0.1,1.4-0.6,7.5-0.6,10.2c0,2,0.3,7.8,0.3,10.2c-0.4,0.4-2,0.4-2.4,0
      c-0.3-2-0.6-3.3-0.9-4.3c-0.6-1.6-1.3-3.3-4-3.6c-3.2-0.3-11.1-0.3-15.4-0.3c-0.1,0-0.4,0.7-0.4,0.9c0,2.6,0,13.9,0.1,20.1
      c0.1,3.3,1.7,4.6,2.4,4.7c2.4,0.6,7,0.7,13.8,0.7c5.9,0,7.2-1.4,8.8-3c2.3-2.6,4.3-6.3,5.9-10.3c0.4-0.1,2,0.3,2.2,0.9
      c-0.3,2.4-3,11.8-5,15.4c-0.3,0.7-1.2,1-3.9,1c-5.5,0-14.5-0.3-29.9-0.3c-6.6,0-11.8,0.4-16.2,0.4c-0.3-0.3-0.3-2,0-2.3
      c2.7-0.1,6-0.6,7.9-1.1c2.4-0.7,3.3-1.6,3.5-5.7c0.1-3.7,0.1-13.6,0.1-25.4c0-9.8-0.1-18.2-0.1-20.1c-0.3-3.3-0.9-4.3-3.3-5.2
      c-1.4-0.4-3.9-0.9-5.7-1C158.3,199.9,158.3,198,158.7,197.9z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M228.3,256.6c2.6,2,6.6,3,10.2,3c6.3,0,11.9-3.3,11.9-10.8c0-5.9-2.7-9.8-14.1-15.7
      c-10.5-5.6-15.5-10.5-15.5-19.5c0-11.3,10.3-17.2,21.5-17.2c4.7,0,7.2,0.4,9.8,0.7c2.3,0.3,5,0.7,6,0.7
      c-0.6,3.2-1.4,9.8-1.7,15.2c-0.3,0.3-2,0.4-2.2,0c-0.4-6.3-1.9-9.5-4.2-11.3c-2.2-1.7-5.5-2.6-9.3-2.6c-7.3,0-10.9,5-10.9,10.5
      c0,5.5,2.9,8.9,7,11.3c4.9,2.9,8.8,4.9,13.1,7.3c5.3,3,10.3,7.5,10.3,15.4c0,10.1-9,18.8-23.7,18.8c-5.9,0-13.6-1.9-15.8-3.2
      c-0.9-1.4-2.2-12.4-2.3-15.9c0.1-0.3,1.7-0.7,2.1-0.6C222.6,250,225.2,254.5,228.3,256.6z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M285.6,257.9c3-0.6,4-2.3,4.2-7.2c0.3-4.5,0.4-32,0.4-48.3c0-0.3-0.4-0.7-0.7-0.7c-1.9-0.1-9.9,0-12.1,0.1
      c-2.3,0.1-4,1.4-5.3,3.7c-1.3,2.3-2.6,5.6-4.2,9.8c-0.3,0-2-0.3-2.2-0.6c0.9-4.2,1.7-13.5,2-17.8c0-0.1,0.3-0.3,0.6-0.1
      c2.7,1.1,5.5,1.3,7.9,1.3H316c3.5,0,5.7,0.1,9.2-1.2c0.1,0,0.4,0.1,0.4,0.4c-1,3.6-1.6,12.8-2,18.1c-0.3,0.3-2.3,0.4-2.3,0
      c-0.4-4.2-1.3-7.5-2-9.3c-1.2-2.9-2.7-3.9-5-4c-3.7-0.3-11.1-0.4-13.2-0.3c-0.3,0.1-0.4,0.4-0.4,0.9c0,14.5,0,45.2,0.4,50.6
      c0.1,2.2,1,3.9,3.3,4.6c1.6,0.4,3.4,0.6,6.2,1c0.4,0.1,0.3,2-0.1,2.2c-3,0-9.3-0.4-15.9-0.4c-7.3,0-12.9,0.6-15.7,0.4
      c-0.4-0.3-0.4-2,0-2.2C282.1,258.5,284.2,258.3,285.6,257.9z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M330.8,197.9c2.4,0,8.2,0.4,13.8,0.4h21.5c5.9,0,9.3-0.1,11.3-0.6c0,0,0.4,0.3,0.4,0.4
      c-1,3.2-2,10.2-2.2,14.8c-0.3,0.3-1.9,0.3-2.2,0c-0.3-2.3-0.7-5.2-1.3-6.6c-0.7-2.9-1.4-4.2-5.3-4.5c-2.6-0.3-13.9-0.1-15.4,0
      c-0.7,0.1-1.2,1.4-1.2,1.9c-0.1,7-0.3,19.8-0.1,22.1c0,0.4,0.3,0.7,0.6,0.7c4.7,0,12.2,0,15.2-0.1c3.2-0.1,3.7-2.2,4.5-4.2
      c0.4-1.3,0.7-2.7,0.9-4c0.3-0.1,2.2-0.1,2.3,0.1c-0.1,1.4-0.6,7.5-0.6,10.2c0,2,0.3,7.8,0.3,10.2c-0.4,0.4-2,0.4-2.4,0
      c-0.3-2-0.6-3.3-0.9-4.3c-0.6-1.6-1.3-3.3-4-3.6c-3.2-0.3-11.1-0.3-15.4-0.3c-0.1,0-0.4,0.7-0.4,0.9c0,2.6,0,13.9,0.1,20.1
      c0.1,3.3,1.7,4.6,2.4,4.7c2.4,0.6,7,0.7,13.8,0.7c5.9,0,7.2-1.4,8.8-3c2.3-2.6,4.3-6.3,5.9-10.3c0.4-0.1,2,0.3,2.2,0.9
      c-0.3,2.4-3,11.8-5,15.4c-0.3,0.7-1.1,1-3.9,1c-5.5,0-14.5-0.3-29.9-0.3c-6.6,0-11.8,0.4-16.2,0.4c-0.3-0.3-0.3-2,0-2.3
      c2.7-0.1,6-0.6,7.9-1.1c2.4-0.7,3.3-1.6,3.4-5.7c0.1-3.7,0.1-13.6,0.1-25.4c0-9.8-0.1-18.2-0.1-20.1c-0.3-3.3-0.9-4.3-3.3-5.2
      c-1.4-0.4-3.9-0.9-5.7-1C330.3,199.9,330.3,198,330.8,197.9z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M389.1,197.9c2.4,0,9.2,0.4,13.4,0.4h18c12.6,0,18.2,6.7,18.2,15.2c0,8.2-6.5,13.5-12.9,15.9
      c-0.1,0.1,0,0.6,0.1,0.7c3.4,5.7,12.8,19.4,18,24.3c3.7,3.9,5.6,4,10.1,4.6c0.1,0,0,2.2-0.3,2.2c-4.3,0.4-13.8,0-16.8-1.4
      c-1.6-0.6-3.2-1.7-4.9-3.7c-4-4.5-11.1-16.1-14.9-21.8c-0.9-1.3-1.6-1.7-3.3-2c-1.3-0.1-3.3-0.1-4.7-0.1
      c-0.3,0-0.4,0.6-0.4,0.7c0,5.7,0.1,15.4,0.4,19.2c0.3,4.3,1.1,5,3.2,5.7c1.3,0.4,3.2,0.7,5.7,1c0.4,0.3,0.1,2-0.1,2.3
      c-2.2,0-8.2-0.4-15.1-0.4c-7.5,0-12.2,0.4-14.2,0.4c-0.3-0.1-0.3-2.2,0-2.3c2.2-0.3,5.2-0.6,6.2-0.9c2.4-0.6,3.4-1.9,3.7-5.7
      c0.1-2.7,0.1-15.9,0.1-21.4c0-8.3,0.1-22.1-0.1-25.6c-0.1-2.7-1.3-3.4-3.2-4c-1.4-0.3-4-0.9-6.2-1
      C388.5,199.9,388.6,198,389.1,197.9z M408.7,203.1c-0.1,3.2-0.3,16.7-0.3,25c0,0.6,0.1,0.9,0.7,0.9c2.3,0.1,8.8,0.1,11.4-0.4
      c3.7-1,7.5-4,7.5-12.4c0-9.3-4.7-14.8-15.2-14.8c-1,0-2.4,0.1-3.3,0.4C409,201.9,408.7,202.6,408.7,203.1z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M451.7,197.8c1.9,0,8.8,0.4,13.4,0.4c2.3,0,5.7,0,6.7-0.1c0.3,0.9,1,2.2,3.6,5.5
      c10.8,13.6,31.3,38.2,35.9,43.5c0.1,0.1,0.3-0.1,0.4-0.3c0-13.4-0.3-33.5-0.7-40.1c-0.1-3-1.3-5.2-4.3-5.7
      c-1.4-0.4-2.4-0.6-5.3-0.9c-0.1-0.4-0.1-2,0-2.3c2.6,0,6.8,0.4,12.2,0.4c3.3,0,9.6-0.4,10.8-0.4c0.1,0.3,0.1,1.9,0,2.2
      c-1.7,0.3-3.9,0.6-5,1c-2.6,0.7-3,2.7-3.2,5.2c-0.6,7.8-0.7,28.7-0.7,41.5c0,4.6,0.1,10.5,0.3,13.6c-2.2-0.1-5.6,0-7.6,0.1
      c-4.5-6.5-36.2-46.1-40.9-51.9c-0.1-0.1-0.3-0.1-0.3,0c-0.1,10.3,0,36.1,0.1,41.8c0.3,4.7,1.6,5.5,3.7,6.5
      c1.4,0.6,4.4,1,6.5,1.1c0.4,0.1,0.3,2,0,2.3c-2,0-8.2-0.4-13.4-0.4c-5,0-9.3,0.4-11.5,0.4c-0.4-0.3-0.4-2.2,0-2.3
      c1.4-0.1,4.2-0.4,6.3-1.1c2.4-0.9,2.9-3,3.2-6.2c0.4-4.6,0.9-22.3,0.9-44.2c0-1.9-0.6-3.4-2.2-4.7c-1.9-1.6-3.7-2.2-9-2.6
      C451.4,199.8,451.4,198,451.7,197.8z"
          ></path>

          <path
            fill="#FFFFFF"
            d="M438,97.9c0,0,29.5,49.1,74.3,66.6C512.2,164.5,450.1,176.8,438,97.9z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M178.3,147.8c0,0,65.6-14.8,131.2-94C309.5,53.8,258.4,171.7,178.3,147.8z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M429.7,87.7c0,0,4.2,18-21.6,33c0,0-30.2-67.6-72.9-73.6c0,0,33.8,0,68.4,39.7
      C415.4,100.3,422.5,96.7,429.7,87.7z"
          ></path>

          <path
            fill="#007AC8"
            d="M167.2,150.3c0,0,28.9,9.7,54.6,4c51.3-11.4,72.7-29.7,105.9-35.4c33.2-5.7,41-3.3,80.5,1.7
      c0,0-8.6-1.5-30.2,2.2c-58,10-88.7,37.6-138.2,41.2C190.2,167.7,167.2,150.3,167.2,150.3z"
          ></path>
          <path
            fill="#007AC8"
            d="M178.3,160.8c0,0,55,16.7,84.9,10c59.6-13.2,84.4-34.5,123-41.1c38.6-6.6,53.7-3.1,99.6,2.7
      c0,0-16.1-2.5-41.1,1.8C377.2,145.9,341.5,178,284,182.2C226.5,186.4,178.3,160.8,178.3,160.8z"
          ></path>

          <path
            fill="#FFFFFF"
            d="M61.4,277c1.9,0,4,0.2,6.6,0.2c3.1,0,4.6-0.1,5.8-0.2c0.2,0.1,0.2,1,0,1.2c-1.2,0.3-1.7,0.4-2.3,0.6
      c-0.8,0.3-1,0.9-0.7,2c1.1,4.7,4.1,15,5.8,20.8c0,0,0.2,0,0.2,0c2-5.5,6.6-18.7,7.8-23.1c0.6,0,1.9,0,2.3,0
      c1.1,4.2,6.5,19.2,8.1,23.4c0.1,0,0.2,0,0.2,0c1.3-4.1,4.8-15.7,5.7-19.8c0.4-1.7,0.5-2.9-1-3.3c-0.8-0.3-1.5-0.4-2.9-0.6
      c-0.2-0.2-0.2-1,0-1.2c1.1,0,3.7,0.2,5.7,0.2c3.1,0,4.5-0.1,5.6-0.2c0.2,0.2,0.2,1,0,1.2c-1.1,0.2-1.8,0.4-2.3,0.6
      c-1.2,0.5-1.8,1.2-2.5,3.2c-2.1,6-6.8,21.1-8.5,26.8c-0.4,0-1.9,0-2.3,0c-1.2-4.4-6-18-8.1-23.7c0,0-0.2,0.1-0.2,0.1
      c-2,5.9-6.6,18.7-8.1,23.5c-0.4-0.1-1.9-0.1-2.4,0c-1.5-6.2-5.8-21.7-7.4-26.6c-0.8-2.4-1.3-3.1-2.7-3.5
      c-0.8-0.3-1.4-0.4-2.5-0.5C61.1,278,61.1,277.1,61.4,277z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M121.8,302.8c2.8-6.6,7.5-19.7,8.7-23c0.4-1,0.7-2,0.8-2.8c0.2,0,0.8,0.1,1.6,0.1c0.9,0,1.4-0.1,1.6-0.1
      c0.2,0.7,0.4,1.5,0.7,2.5c1.1,3,6.7,19.7,8.3,23.8c1.5,3.4,1.7,3.6,5.3,4c0.2,0.2,0,1-0.1,1.2c-1.3,0-4-0.2-6-0.2
      c-2.2,0-5.2,0.1-6.4,0.2c-0.1-0.2-0.2-1,0-1.2c1-0.2,1.9-0.3,2.6-0.6c0.8-0.3,0.8-0.8,0.6-1.3c-0.6-2.1-2.3-6.8-3.2-9.3
      c-0.1-0.1-0.4-0.3-0.4-0.3c-0.7-0.1-2.1-0.1-4.6-0.1c-3.2,0-3.7,0-4.2,0.1c-0.2,0-0.4,0.3-0.5,0.4c-1,2.7-2.8,7.4-2.9,8.7
      c-0.1,0.9-0.1,1.5,1.5,1.9c0.6,0.2,1.3,0.3,2.3,0.4c0.1,0.2,0.1,1-0.1,1.2c-1.1,0-3.6-0.2-6.2-0.2s-4.2,0.2-5.2,0.2
      c-0.2-0.2-0.2-1,0-1.2C119.6,307,120.2,306.6,121.8,302.8z M131.4,282.2c-0.9,2.4-3.1,8.7-3.9,11.2c0,0.2,0,0.3,0.2,0.4
      c0.5,0.1,1.9,0.1,4.1,0.1c1.2,0,3,0,3.4-0.1c0,0,0.2-0.2,0.2-0.4c-1.1-3.3-3-9-3.8-11.2C131.6,282.2,131.4,282.1,131.4,282.2z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M169.1,306.4c1.3,0.9,3,1.3,5,1.3c2.8,0,5.5-1.7,5.5-5.5c0-3.4-2.1-5.1-6.6-7.8c-4.3-2.6-7.4-5.2-7.4-9.6
      c0-5.4,4.4-8.6,9.8-8.6c3.4,0,5.4,0.6,7.5,0.8c-0.4,1.7-0.7,4.3-0.9,6.9c-0.2,0.2-1.1,0.2-1.2,0c-0.3-3.2-0.8-4.6-2-5.4
      c-1.1-0.7-2.4-1-4-1c-3.5,0-5.4,2.6-5.4,5.3c0,2.8,1.3,4.4,3.4,5.9c2,1.4,4.3,2.5,6,3.6c2.6,1.7,4.9,3.8,4.9,7.6
      c0,5-4.1,9.3-11.1,9.3c-3,0-6-0.9-7.1-1.5c-0.5-0.7-1.2-5.8-1.3-7.4c0.2-0.3,1.1-0.5,1.3-0.4
      C166.4,303.3,167.4,305.3,169.1,306.4z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M201.5,277c1.4,0,3.9,0.2,6.4,0.2c3.2,0,4.6-0.1,6-0.2c0.2,0.2,0.1,1-0.1,1.2c-0.8,0.1-1.7,0.3-2.3,0.4
      c-1,0.3-1.4,0.7-1.4,2.3c-0.1,2,0,5.1,0,9.9c0,0.2,0.1,0.4,0.3,0.5c0.3,0.1,1.1,0.1,8.7,0.1c5.1,0,7.4,0,7.9-0.1
      c0.2,0,0.3-0.2,0.3-0.3c0-2.7,0-8.8-0.1-10.7c0-0.8-0.3-1.4-1-1.7c-0.8-0.3-1.7-0.4-2.8-0.5c-0.3-0.2-0.2-1,0-1.2
      c1.1,0,3.5,0.2,6.2,0.2c2.5,0,5.2-0.2,6-0.2c0.2,0.2,0.2,1,0,1.2c-1,0.2-1.8,0.3-2.4,0.5c-1.1,0.4-1.4,0.8-1.5,2.8
      c-0.1,1.8-0.2,5.2-0.2,11.4c0,4.3,0.1,10,0.2,11.7c0.1,1.5,0.4,2,1.4,2.3c0.7,0.3,1.5,0.4,3.1,0.6c0.1,0.2,0.1,1-0.2,1.2
      c-1.7,0-3.9-0.2-6.4-0.2c-2.5,0-5.1,0.1-6.3,0.2c-0.2-0.2-0.2-1,0-1.2c1.1-0.1,1.8-0.2,2.7-0.5c1-0.3,1.3-0.8,1.4-2.5
      c0.1-1.5,0.1-7.3,0.1-10.6c0-0.2-0.1-0.4-0.3-0.4c-0.9,0-5.3,0-8,0c-4.1,0-8.2,0-8.7,0c-0.1,0.1-0.2,0.3-0.2,0.3
      c0,2.8,0,8.9,0,10c0.2,1.7,0.2,2.6,1.5,3c0.7,0.2,1.4,0.4,2.9,0.6c0.2,0.2,0,1.1-0.2,1.2c-1.2,0-3.4-0.2-6.3-0.2
      c-2.7,0-5.5,0.1-6.6,0.2c-0.2-0.2-0.2-1,0-1.2c1.2-0.2,1.9-0.3,2.6-0.5c1.5-0.4,1.6-1.4,1.7-3.8c0-1.7,0-6.3,0-10.7
      c0-4.9,0-10.6-0.1-11.5c-0.1-1.5-0.5-2-1.4-2.2c-0.8-0.3-1.6-0.3-2.9-0.5C201.3,278,201.4,277.2,201.5,277z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M255.3,277c1.1,0,3.8,0.2,6.4,0.2c2.7,0,4.7-0.2,5.9-0.2c0.2,0.2,0.2,1,0,1.2c-1,0.1-1.8,0.3-2.4,0.4
      c-1.1,0.4-1.3,1-1.4,2.7c-0.1,2-0.1,6.7-0.1,11.1c0,5.2,0,10.1,0.1,11.9c0,1.5,0.3,2.3,1.6,2.8c0.7,0.2,1.7,0.3,2.8,0.5
      c0.2,0.2,0,1-0.1,1.2c-1.3,0-3.6-0.2-6.1-0.2c-3,0-4.8,0.2-6.8,0.2c-0.2-0.2-0.2-1,0-1.2c0.9-0.1,1.8-0.2,2.4-0.4
      c1.4-0.3,1.6-1,1.7-2.3c0.1-1.5,0.2-7.9,0.2-13.6c0-4.2,0-7.7-0.1-10.1c-0.2-1.2-0.2-2.1-1.3-2.4c-0.7-0.2-1.6-0.4-2.9-0.5
      C255.1,278,255.2,277.2,255.3,277z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M287.9,277c1.2,0,3.7,0.2,5.9,0.2c1.1,0,1.9,0,2.3-0.1c0.2,0.5,0.6,1.1,1.9,2.8c5.3,6.6,16.1,19.7,18.4,22.6
      l0.2-0.1c0-6.8,0.1-17.3-0.2-20.8c-0.1-1.5-0.4-2.3-1.9-2.8c-0.9-0.3-1.9-0.4-3.3-0.6c-0.2-0.2-0.1-1,0-1.2
      c1.3,0,3.6,0.2,6.4,0.2c1.7,0,4.5-0.2,5.1-0.2c0.2,0.2,0.2,1,0,1.2c-0.9,0.2-1.5,0.3-2.1,0.5c-1.4,0.4-1.7,1.4-1.7,2.8
      c-0.3,4-0.3,12.2-0.4,20.4c0,2.3,0.1,5.3,0.1,6.9c-0.6,0-2.7,0-3.3,0c-2.6-3.8-17.3-22.1-20-25.5c-0.1-0.1-0.2,0-0.2,0
      c0,5.2,0,17.7,0.1,20.5c0.2,2.2,0.8,2.9,2,3.2c0.8,0.2,1.8,0.3,2.9,0.5c0.2,0.2,0.1,1,0,1.2c-1.2,0-3.4-0.2-6.1-0.2
      c-2.8,0-4.6,0.2-5.7,0.2c-0.2-0.2-0.2-1.1,0-1.2c1.1-0.1,1.7-0.2,2.4-0.5c1.2-0.3,1.9-0.9,2-3.2c0.2-2.5,0.4-11.3,0.5-21.9
      c0-0.7-0.3-1.9-1.3-2.5c-1-0.8-2.1-1-4-1.2C287.7,278,287.7,277.2,287.9,277z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M367.7,307.5c-1.6,0.4-6.5,1.9-10.9,1.9c-11.7,0-18-5.8-18-16.8c0-6.6,3.4-11.9,8.9-14.4
      c2.9-1.3,5.8-1.9,9.9-1.9c4,0,6.9,0.9,9.7,1.1c-0.4,1.7-0.8,5.5-0.8,7.8c-0.2,0.3-1.2,0.4-1.4,0.2c-0.4-3.8-1-5.2-2.5-6
      c-1.6-1-3.8-1.5-6.9-1.5c-8,0-11.9,5.6-11.9,14.3c0,8.9,4.7,15.7,13.8,15.7c1.4,0,2.8-0.2,3.5-0.6c0.8-0.4,1.2-1.2,1.3-2.2
      c0.1-1.6,0.2-3.8,0.1-5.5c-0.1-1.8-0.5-2.4-2-2.7c-0.9-0.2-1.6-0.3-3-0.4c-0.2-0.2-0.2-1.1,0-1.2c1.7,0,4.1,0.2,6.9,0.2
      c2.8,0,4.1-0.1,5.2-0.2c0.2,0.2,0.2,1.1-0.1,1.2c-0.7,0.1-1.2,0.2-1.6,0.4c-1,0.3-1.2,1-1.3,2.7c0,1.6-0.1,4.6,0,5.7
      c0.1,0.8,0.2,1,1.2,1.4C367.9,306.7,367.8,307.3,367.7,307.5z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M408.2,308.6c-1.5,0-4.4-0.2-7-0.2c-3.5,0-6.1,0.2-7.5,0.2c-0.2-0.2-0.2-1,0-1.2c1.5-0.2,2.8-0.3,3.5-0.5
      c1.5-0.3,1.8-1.3,1.9-3.7c0.1-3.2,0.1-15.6,0.1-23.9c0-0.2-0.2-0.4-0.3-0.4c-0.9-0.1-5.3-0.1-6.8,0c-0.9,0-1.5,0.4-2.1,1.3
      c-0.6,1-1.3,2.9-1.9,4.6c-0.2,0.1-1.2-0.1-1.2-0.3c0.4-2.1,0.8-6,0.9-8.1c0,0,0.4-0.3,0.5-0.2c0.9,0.5,2.4,0.7,4,0.7H411
      c2.1,0,3.3-0.1,4.7-0.7c0.2,0,0.4,0.1,0.4,0.2c-0.5,1.6-0.9,5.4-1.1,8.2c-0.1,0.2-1.2,0.2-1.3,0c-0.2-1.5-0.4-3.3-0.8-4.3
      c-0.4-1-1-1.4-2-1.4c-2.1-0.2-6.2-0.1-7.3-0.1c-0.2,0.1-0.3,0.3-0.3,0.4c0,7.4,0,21.7,0.2,24.4c0.1,2.3,0.6,2.8,1.7,3.1
      c0.8,0.2,1.5,0.3,3,0.5C408.5,307.6,408.4,308.5,408.2,308.6z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M435.9,304.7c-3.1-3.4-4.4-7.7-4.4-12.1c0-3.7,1.1-7,3.1-9.7c2.8-4,8.3-6.6,13.6-6.6
      c10.3,0,15.5,7.6,15.5,15.9c0,4.3-1,7.6-3.1,10.5c-3.1,4.4-8.2,6.7-13.3,6.7C442.6,309.4,438.5,307.5,435.9,304.7z M454.8,305.6
      c3.2-2.7,4.3-6.9,4.3-11.7c0-5.4-1.8-10.8-5.8-13.7c-1.8-1.3-4-2.1-6.7-2.1c-1.8,0-3.4,0.5-4.7,1.2c-3.7,2.2-5.4,6.9-5.4,12.1
      c0,5.3,1.9,11.2,6.4,14.2c1.8,1.2,3.9,1.8,6.5,1.8C451.4,307.4,453.4,306.7,454.8,305.6z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M480.5,277c1.2,0,3.7,0.2,5.9,0.2c1.1,0,1.9,0,2.3-0.1c0.2,0.5,0.6,1.1,1.9,2.8c5.3,6.6,16.1,19.7,18.4,22.6
      l0.2-0.1c0-6.8,0.1-17.3-0.2-20.8c-0.1-1.5-0.4-2.3-1.9-2.8c-0.9-0.3-1.9-0.4-3.3-0.6c-0.2-0.2-0.1-1,0-1.2
      c1.3,0,3.6,0.2,6.4,0.2c1.7,0,4.5-0.2,5.1-0.2c0.2,0.2,0.2,1,0,1.2c-0.9,0.2-1.5,0.3-2.1,0.5c-1.4,0.4-1.7,1.4-1.7,2.8
      c-0.3,4-0.3,12.2-0.4,20.4c0,2.3,0.1,5.3,0.1,6.9c-0.6,0-2.7,0-3.3,0c-2.6-3.8-17.3-22.1-20-25.5c-0.1-0.1-0.2,0-0.2,0
      c0,5.2,0,17.7,0.1,20.5c0.2,2.2,0.8,2.9,2,3.2c0.8,0.2,1.8,0.3,2.9,0.5c0.2,0.2,0.1,1,0,1.2c-1.2,0-3.4-0.2-6.1-0.2
      c-2.8,0-4.6,0.2-5.7,0.2c-0.2-0.2-0.2-1.1,0-1.2c1.1-0.1,1.7-0.2,2.4-0.5c1.2-0.3,1.9-0.9,2-3.2c0.2-2.5,0.4-11.3,0.5-21.9
      c0-0.7-0.3-1.9-1.3-2.5c-1-0.8-2.1-1-4-1.2C480.3,278,480.3,277.2,480.5,277z"
          ></path>

          <path
            fill="#FFFFFF"
            d="M64.6,323c0.9,0,4.1,0.1,6.8,0.1s5-0.2,6.4-0.2c0.2,0.2,0.2,1.1,0,1.3c-1.1,0.2-1.8,0.3-2.5,0.5
      c-1.2,0.3-1.5,0.9-1.6,3c-0.1,5-0.1,13.5,0.1,16.4c0.2,3.1,0.6,5.1,1.4,6.5c1.4,3,4.7,5,9.3,5c4.8,0,9.2-1.7,9.7-10.3
      c0.2-4.2,0.4-11,0.1-16.6c-0.1-3-0.8-3.6-2.5-4c-0.8-0.2-1.5-0.3-2.6-0.4c-0.2-0.2-0.1-1.1,0.1-1.2c1,0,3.2,0.1,6.3,0.1
      c2.5,0,4.6-0.1,5.6-0.1c0.2,0.1,0.2,1.1,0,1.2c-0.8,0.2-1.6,0.3-2.2,0.5c-1.5,0.4-1.8,1.3-2,4.2c-0.3,11.5-0.2,15.3-0.9,19.1
      c-1.4,7.9-7.2,10.3-13.3,10.3c-8.5,0-12.5-3.9-13.3-10.1c-0.4-3.5-0.3-17-0.4-20.6c-0.1-1.5-0.3-2.5-1.5-2.8
      c-0.6-0.2-1.9-0.4-2.8-0.5C64.4,324.1,64.4,323.1,64.6,323z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M118.7,323c1.3,0,4,0.2,6.4,0.2c1.2,0,2.1,0,2.6-0.1c0.2,0.5,0.6,1.2,2.1,3c5.7,7.2,17.6,21.6,20.1,24.7
      l0.2-0.1c0-7.5,0.1-18.9-0.2-22.8c-0.1-1.7-0.4-2.6-2.1-3c-1-0.3-2.1-0.4-3.6-0.6c-0.2-0.2-0.1-1.1,0.1-1.3c1.5,0,3.9,0.2,7,0.2
      c1.8,0,4.9-0.2,5.6-0.2c0.2,0.2,0.2,1.1,0,1.3c-0.9,0.2-1.6,0.3-2.3,0.5c-1.5,0.4-1.8,1.5-1.9,3c-0.3,4.4-0.3,13.3-0.4,22.3
      c0,2.6,0.1,5.7,0.1,7.5c-0.6-0.1-2.9-0.1-3.6,0c-2.8-4.1-19-24.2-21.9-27.9c-0.1-0.1-0.2,0-0.2,0.1c-0.1,5.7-0.1,19.4,0.1,22.4
      c0.2,2.5,0.9,3.1,2.2,3.5c0.8,0.2,2,0.3,3.2,0.5c0.2,0.2,0.1,1.1-0.1,1.3c-1.3,0-3.7-0.2-6.7-0.2c-3.1,0-5,0.2-6.2,0.2
      c-0.2-0.2-0.2-1.2,0-1.3c1.2-0.1,1.9-0.2,2.7-0.5c1.3-0.3,2.1-1,2.2-3.5c0.2-2.7,0.4-12.4,0.5-23.9c0-0.8-0.3-2.1-1.4-2.7
      c-1.1-0.9-2.3-1.1-4.4-1.3C118.5,324.1,118.5,323.1,118.7,323z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M175.8,323c1.2,0,4.2,0.2,7,0.2c3,0,5.2-0.2,6.5-0.2c0.2,0.2,0.2,1.1,0.1,1.3c-1.1,0.1-2,0.3-2.6,0.4
      c-1.2,0.4-1.5,1.1-1.6,2.9c-0.1,2.2-0.1,7.3-0.1,12.2c0,5.7,0,11,0.1,13c0.1,1.6,0.3,2.6,1.8,3c0.8,0.2,1.9,0.3,3.1,0.5
      c0.2,0.2,0.1,1.1-0.1,1.3c-1.4,0-4-0.2-6.6-0.2c-3.2,0-5.2,0.2-7.5,0.2c-0.2-0.2-0.2-1.1,0-1.3c1-0.1,2-0.2,2.6-0.4
      c1.5-0.3,1.7-1.1,1.9-2.5c0.1-1.6,0.2-8.6,0.2-14.8c0-4.6,0-8.4-0.1-11c-0.2-1.3-0.2-2.3-1.5-2.6c-0.7-0.2-1.7-0.4-3.2-0.6
      C175.6,324.1,175.7,323.1,175.8,323z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M206.7,323c1.3,0,4.2,0.2,6.6,0.2c3.3,0,5.8-0.1,6.8-0.2c0.2,0.2,0.2,1.1,0,1.3c-1.3,0.2-2.1,0.4-2.7,0.6
      c-0.8,0.2-1,0.8-0.7,1.6c1.7,4.8,8.3,22.3,9.3,24.6c0.1-0.1,0.2,0,0.2-0.1c1.1-2.5,7.4-18.4,9-23c0.6-2,0.4-2.8-1.2-3.2
      c-0.9-0.2-1.7-0.3-3-0.5c-0.1-0.2,0-1.2,0.2-1.3c1.1,0,3.2,0.2,6.2,0.2c2.8,0,4.5-0.2,5.6-0.2c0.2,0.2,0.1,1.1,0,1.3
      c-0.9,0.1-1.7,0.3-2.4,0.5c-1.6,0.5-2.2,1.6-3.6,4.9c-1.7,3.9-5.7,13.9-8.5,20.8c-1.2,3.1-2.1,5.6-2.6,7.3c-0.8-0.2-2.3-0.2-3,0
      c-0.6-2.2-1.4-4.7-2.5-7.6c-2.8-7.5-6.4-17.1-8.1-21.3c-1.2-2.9-1.8-3.8-3.4-4.2c-0.7-0.2-1.5-0.3-2.5-0.4
      C206.3,324,206.5,323.1,206.7,323z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M258.7,323c1.3,0,3.9,0.2,6.9,0.2h10.8c2.1,0,5-0.1,6.1-0.2c0.1,0,0.2,0.2,0.2,0.2c-0.4,1.7-0.8,5.3-0.9,7.5
      c-0.2,0.2-1.2,0.2-1.4,0.1c-0.2-1.7-0.4-2.7-0.6-3.5c-0.4-1.3-0.9-2-2.8-2.1c-1.2-0.1-7.6-0.1-8.4,0c-0.4,0.1-0.6,0.7-0.6,0.9
      c-0.1,2.8-0.1,10.4-0.1,12.3c0,0.2,0.1,0.4,0.3,0.4c1.8,0.1,6.8,0.1,8.5-0.1c1.5-0.1,1.8-0.8,2.2-1.8c0.2-0.6,0.4-1.3,0.6-2.1
      c0.3-0.2,1.3-0.1,1.4,0.1c-0.2,1.1-0.4,3.2-0.4,5.1c0,2.1,0.1,3.7,0.1,4.9c-0.2,0.2-1.3,0.3-1.5,0c-0.2-1.1-0.2-1.5-0.4-2.1
      c-0.2-0.7-0.6-1.6-2.1-1.7c-1.6-0.2-6.6-0.2-8.5-0.1c-0.1,0-0.3,0.3-0.3,0.4c0,2.1,0,8.7,0.1,11.7c0.1,1.2,0.7,1.9,1.3,2.1
      c1.1,0.2,4.7,0.3,7.6,0.3c2.9-0.1,3.6-0.6,4.5-1.7c0.9-0.9,2.1-3.1,3-5c0.2-0.1,1.1,0.3,1.2,0.5c-0.3,1.7-1.6,6-2.3,7.4
      c-0.3,0.6-0.6,0.8-3.3,0.8c-4,0-7.4-0.2-14.1-0.2c-3.6,0-6,0.2-8.3,0.2c-0.2-0.2-0.2-1.1,0-1.3c1.6-0.2,2.9-0.3,3.9-0.6
      c1.3-0.3,1.7-0.9,1.8-3.1c0.1-1.2,0.1-7.4,0.1-13.7c0-5.2,0-10.3-0.1-11.3c-0.1-2.1-0.3-2.6-1.7-2.9c-0.7-0.2-1.6-0.3-2.8-0.5
      C258.5,324,258.5,323.1,258.7,323z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M305.2,323c1.3,0,4.5,0.2,6.1,0.2c3.8,0,6.6-0.1,9-0.1c6.5,0,9.8,3.7,9.8,8.5c0,4.7-3.1,7.4-6.4,8.9
      c-0.1,0.1-0.1,0.3-0.1,0.3c1.9,3.1,6.5,9.8,9.2,12.5c2.4,2.4,3.2,2.7,6.1,3c0.1,0.1,0,1.2-0.2,1.3c-2.3,0.1-6.7-0.1-8.6-1
      c-0.8-0.4-1.7-1.1-2.6-2.2c-2.1-2.4-5.7-8.4-7.8-11.4c-0.4-0.6-0.9-0.9-1.3-0.9c-0.8-0.1-3,0-3.7,0c-0.1,0-0.2,0.2-0.2,0.3
      c0,3.1,0,8.4,0.1,10.4c0.1,2.1,0.5,2.6,1.7,2.8c0.8,0.2,1.8,0.3,3,0.5c0.2,0.2,0.1,1.1-0.1,1.3c-1,0-3.8-0.2-7-0.2
      c-3.7,0-5.9,0.2-6.9,0.2c-0.2-0.2-0.2-1.1,0-1.3c1.2-0.2,2.1-0.3,2.7-0.4c1.3-0.3,1.7-1,1.8-3.1c0.1-1.5,0.2-9.3,0.2-12.3
      c0-4.8,0-11.2-0.1-13.2c-0.1-1.5-0.4-2.2-1.8-2.6c-0.8-0.2-1.8-0.3-2.8-0.4C305,324,305,323.1,305.2,323z M314.8,324.9
      c-0.1,0.1-0.3,0.6-0.3,0.8c-0.1,1.7-0.1,9.3-0.1,14.1c0,0.2,0.2,0.3,0.2,0.3c3,0.2,5.8,0.1,7-0.4c1.8-0.7,3.7-2.6,3.7-6.7
      c0-5.2-2.7-8.3-8.6-8.3C316.2,324.7,315.4,324.7,314.8,324.9z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M359.9,355.1c1.4,1,3.2,1.5,5.5,1.5c3.1,0,6-1.8,6-6c0-3.7-2.3-5.6-7.2-8.5c-4.7-2.8-8.1-5.6-8.1-10.5
      c0-5.9,4.8-9.4,10.8-9.4c3.7,0,5.9,0.7,8.2,0.9c-0.4,1.9-0.7,4.7-0.9,7.5c-0.2,0.2-1.2,0.2-1.3,0.1c-0.3-3.5-0.8-5-2.2-5.9
      c-1.2-0.8-2.7-1.1-4.4-1.1c-3.9,0-5.9,2.8-5.9,5.8c-0.1,3.1,1.5,4.8,3.7,6.4c2.2,1.5,4.7,2.7,6.6,3.9c2.8,1.8,5.4,4.2,5.4,8.3
      c0,5.5-4.5,10.2-12.1,10.2c-3.3,0-6.6-1-7.8-1.7c-0.5-0.8-1.3-6.3-1.5-8.1c0.2-0.3,1.2-0.5,1.4-0.4
      C356.8,351.7,358,353.9,359.9,355.1z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M397.1,323c1.2,0,4.2,0.2,7,0.2c3,0,5.2-0.2,6.5-0.2c0.2,0.2,0.2,1.1,0.1,1.3c-1.1,0.1-2,0.3-2.6,0.4
      c-1.2,0.4-1.5,1.1-1.6,2.9c-0.1,2.2-0.1,7.3-0.1,12.2c0,5.7,0,11,0.1,13c0.1,1.6,0.3,2.6,1.8,3c0.8,0.2,1.9,0.3,3.1,0.5
      c0.2,0.2,0.1,1.1-0.1,1.3c-1.4,0-4-0.2-6.6-0.2c-3.2,0-5.2,0.2-7.5,0.2c-0.2-0.2-0.2-1.1,0-1.3c1-0.1,2-0.2,2.6-0.4
      c1.5-0.3,1.7-1.1,1.9-2.5c0.1-1.6,0.2-8.6,0.2-14.8c0-4.6,0-8.4-0.1-11c-0.2-1.3-0.2-2.3-1.5-2.6c-0.7-0.2-1.7-0.4-3.2-0.6
      C396.8,324.1,396.9,323.1,397.1,323z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M454.7,357.5c-1.7,0-4.9-0.2-7.7-0.2c-3.8,0-6.7,0.2-8.2,0.2c-0.2-0.2-0.2-1.1,0-1.3c1.7-0.2,3-0.3,3.8-0.5
      c1.7-0.3,2-1.5,2.1-4c0.1-3.5,0.1-17.1,0.1-26.2c0-0.2-0.2-0.4-0.3-0.4c-1-0.1-5.7-0.1-7.4,0.1c-0.9,0-1.7,0.4-2.3,1.5
      c-0.7,1.1-1.5,3.1-2.1,5.1c-0.2,0.1-1.3-0.1-1.3-0.3c0.4-2.3,0.9-6.6,1-8.9c0-0.1,0.4-0.3,0.5-0.2c1,0.5,2.7,0.8,4.4,0.8h20.6
      c2.3,0,3.6-0.1,5.2-0.7c0.2,0,0.4,0.1,0.4,0.2c-0.6,1.8-1,5.9-1.2,8.9c-0.1,0.2-1.3,0.2-1.5,0c-0.2-1.7-0.4-3.6-0.8-4.7
      c-0.4-1.1-1.1-1.6-2.2-1.6c-2.3-0.2-6.7-0.1-8-0.1c-0.2,0.1-0.3,0.3-0.3,0.4c0,8.1,0.1,23.8,0.2,26.7c0.1,2.5,0.7,3,1.9,3.4
      c0.8,0.2,1.7,0.3,3.3,0.5C455,356.4,454.9,357.4,454.7,357.5z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M482.3,323c1.5,0,4.2,0.2,6.9,0.2c2,0,5.2-0.1,5.9-0.2c0.2,0.2,0.3,1.1,0.1,1.3c-0.6,0.1-1.5,0.3-2,0.4
      c-0.8,0.2-0.9,0.7-0.4,1.7c1.5,3.1,6,11.8,7.1,13.8c0,0,0.2,0,0.3-0.1c1.9-3.2,5.8-10.2,7-13.1c0.5-1.2,0.4-2-1.2-2.3
      c-0.8-0.2-1.3-0.3-2.5-0.4c-0.2-0.2-0.1-1.2,0.1-1.3c1.6,0.1,4,0.2,5.9,0.2c2.5,0,3.8-0.1,5.7-0.2c0.2,0.1,0.2,1.1,0.1,1.3
      l-2.3,0.4c-1.7,0.4-2.5,1.5-3.8,3.5c-2.3,3.8-6.6,11.4-7.8,13.7c-0.2,0.5-0.4,1.4-0.4,1.9l0.1,8.3c0.1,2.3,0.3,3.4,2,3.7
      c0.8,0.2,1.6,0.3,3,0.4c0.1,0.2,0.1,1.1-0.1,1.2c-1.3,0-4.5-0.2-7.4-0.2c-3.6,0-5.9,0.2-7.5,0.2c-0.2-0.1-0.2-1.1,0-1.2
      c1.3-0.1,2.6-0.3,3.5-0.5c1.6-0.3,1.8-1.5,1.8-3.2l0.1-7.6c0-0.8-0.2-2-0.4-2.7c-1.2-2.7-4.5-9-6.7-12.9c-2.2-4-2.8-4.4-4.6-4.8
      c-0.7-0.2-1.4-0.3-2.5-0.4C482,324,482.1,323.2,482.3,323z"
          ></path>

          <path
            fill="#FFFFFF"
            d="M128.1,490.1v-29l-1.8,6l-8.3,23h-5.2l-8.2-23l-1.8-6v29h-5.6V453h8.5l8.1,23.8l1.8,6l1.7-6l8-23.8h8.5v37.1
      H128.1z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M168.5,490.1l-3-9.5h-12.6l-2.9,9.5h-6.2l12.4-37.1h6.1l12.5,37.1H168.5z M159.1,460.8l-4.6,14.9h9.4
      L159.1,460.8z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M205.9,490.1l-10.7-16.4l-4.1,4.6v11.9h-6.1V453h6.1v17.8l14.8-17.8h7.1l-13.8,16.3
      c1.3,1.2,13.9,20.8,13.9,20.8H205.9z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M223.5,490.1V453h23.5v5.5h-17.3v9.7h12.8v5.1h-12.8v11.4h17.3v5.5H223.5z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M309.8,490.1h-5.9l-7.2-26.9l-7.5,26.9h-5.9l-9.8-37.1h6.5l6.4,28.1L294,453h5.5l7.5,28.1l6.5-28.1h6.2
      L309.8,490.1z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M349.2,490.1l-3-9.5h-12.6l-2.9,9.5h-6.2l12.4-37.1h6.1l12.5,37.1H349.2z M339.8,460.8l-4.6,14.9h9.4
      L339.8,460.8z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M378,490.1h-5.6L359.5,453h6.6l9.4,29l9.2-29h6.5L378,490.1z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M401.2,490.1V453h23.5v5.5h-17.3v9.7h12.8v5.1h-12.8v11.4h17.3v5.5H401.2z"
          ></path>
          <path
            fill="#FFFFFF"
            d="M448.5,491.1c-8.8,0-14-4.5-14-10.9v-2h6.1v0.8c0,4.8,3.7,6.7,8.3,6.7c4.4,0,6.5-2.5,6.5-5.6
      c0-8.2-20.4-4.8-20.4-17.3c0-6,4.8-11,13.1-11c8.4,0,13.7,4.9,13.7,10.9v1.6h-6.1v-0.8c0-3.6-2.7-6.4-7.6-6.4
      c-4.3,0-6.9,2.2-6.9,5.2c0,7.9,20.4,4.7,20.4,17.1C461.7,486.1,456.9,491.1,448.5,491.1z"
          ></path>
          <path fill="#FFFFFF" d="M473.4,490.1v-6.5h5.5v6.5H473.4z"></path>

          <line
            x1="60.5"
            y1="405.1"
            x2="516.7"
            y2="405.1"
          ></line>
        </svg>
      </div>
    );
  }
}

export default WWULogo;