import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { BrowserAuthError, InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginSilentRequest } from "./AuthConfig";
import { fetchSelf } from "./DataService";
import LoadMask from "../components/LoadMask";
import jwt_decode from "jwt-decode";

const Login = () => {
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
  const { instance, accounts, inProgress } = useMsal();
  const [message, setMessage] = useState("");
  const [shouldLogin, setShouldLogin] = useState(true);
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    const popupError = (error) => {
      if (
        error instanceof BrowserAuthError &&
        error.errorCode === "user_cancelled"
      ) {
        setMessage("Cancelled by user.");
      } else {
        setMessage(error.message);
      }
      console.log(message);
      setShouldLogin(false);
    };

    const silentLogin = async () => {
      if (accounts.length > 0 && inProgress === InteractionStatus.None) {
        const silentRequest = {
          ...loginSilentRequest,
          account: accounts[0],
        };

        try {
          const response = await instance.acquireTokenSilent(silentRequest);
          if (response.accessToken) {
            fetchUserDetails();
          }
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            // Interactive login is needed (consent required)
            instance.loginPopup({ scopes: ["User.Read"] }).catch(popupError);
          } else {
            console.error("Silent authentication failed:", error);
            instance.loginPopup().catch(popupError);
          }
        }
      } else if (
        accounts.length === 0 &&
        inProgress === InteractionStatus.None
      ) {
        instance.loginPopup({scopes: ["User.Read"]}).catch(popupError);
      }
    };
    
    const fetchUserDetails = () => {
      fetchSelf().then((user) => {
        if (
          user == null ||
          user === "undefined" ||
          JSON.stringify(user) === "undefined"
        ) {
          localStorage.removeItem("user");
        } else {
          localStorage.setItem("user", JSON.stringify(user));
        }
        const from = localStorage.getItem("from");
        if (from) {
          localStorage.removeItem("from");
          window.location.href = from;
        } else {
          window.location.href = "/found";
        }
      });
    };

    if (
      !isAuthenticated &&
      inProgress === InteractionStatus.None &&
      shouldLogin
    ) {
      silentLogin();
    }
  }, [instance, isAuthenticated, inProgress, accounts, shouldLogin, message]);


  return (
    <div id="error-page">
      <h1>Hello!</h1>
      <p>Getting things ready for you.</p>
      <div>
        <p>
          <i>Please complete the login popup.</i>
        </p>
        <LoadMask />
        <Button onClick={() => instance.loginPopup()}>No Popup?</Button>
      </div>
    </div>
  );
};

const Logout = () => {
  const { instance, accounts, inProgress } = useMsal();

  const handleLogout = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  if (accounts.length > 0 && inProgress === InteractionStatus.None) {
    setTimeout(handleLogout, 3000);
  }

  return (
    <div id="error-page">
      <h1>Farewell!</h1>
      <p>Please wait while we log you out.</p>
      <p>
        <i>
          {accounts.length === 0
            ? "Redirecting..."
            : "Logout on the following screen."}
        </i>
      </p>
    </div>
  );
};

const isAuthenticated = () => {
  let id_token = window.sessionStorage.getItem("id_token");
  var decoded = {};
  if (id_token) {
    decoded = jwt_decode(id_token);
  }
  if (decoded && decoded.preferred_username) {
    return true;
  }
  return false;
};

export { isAuthenticated, Login, Logout };
