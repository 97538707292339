import React from "react";
import {
  Form,
  FormControl,
  Button,
  Row,
  Col,
  Table,
  Container,
  FormLabel,
} from "react-bootstrap";
import { Navigate } from "react-router-dom";
import {
  fetchUsers,
  fetchSettings,
  fetchCategories,
  fetchAllSubcategories,
  putUser,
  putSetting,
  putSubcategories,
  postUser,
  postSetting,
  deleteUser,
  deleteSetting,
} from "./services/DataService";
import UserModal from "./components/UserModal";
import SettingModal from "./components/SettingModal";
import LoadMask from "./components/LoadMask";
import "./style/admin.scss";

class Admin extends React.Component {
  constructor(props) {
    let user = localStorage.getItem("user");
    let isSuperuser = false;
    if (user !== null) {
      user = JSON.parse(user);
      if (user.is_superuser) {
        isSuperuser = true;
      }
    }
    super(props);
    this.state = {
      isSuperuser: isSuperuser,
      loading: true,
      users: [],
      filteredUsers: [],
      userSearchTerms: [],
      usersActive: "All",
      usersSuper: "All",
      showUserModal: false,
      redirect: false,
      setPrice: false,
    };
    this.timeout = null;
    this.search = "";
    this.filterUsers = this.filterUsers.bind(this);
    this.onUserSearchChange = this.onUserSearchChange.bind(this);
  }

  componentDidMount() {
    Promise.all([
      fetchUsers(),
      fetchCategories(),
      fetchAllSubcategories(),
      fetchSettings(),
    ]).then(([users, categories, subcategories, settings]) => {
      let categoryMap = {};
      categories.forEach((category) => {
        categoryMap[category["ID"]] = category;
      });
      this.setState(
        {
          users: users,
          categoryMap: categoryMap,
          subcategories: subcategories,
          settings: settings,
        },
        this.filterUsers
      );
      subcategories.map((s, _) => this.setState({ [s.ID]: s.Price }));
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let target = event.target.querySelector("input");
    let item = {
      ID: parseInt(target.id),
      Name: target.name,
      Price: parseFloat(parseFloat(target.value).toFixed(2)),
    };
    putSubcategories(item).then();
  };

  filterUsers() {
    let filteredUsers = this.state.users;
    if (this.state.usersActive !== "All") {
      filteredUsers = filteredUsers.filter(
        (user) => user.is_active === (this.state.usersActive === "Active")
      );
    }
    if (this.state.usersSuper !== "All") {
      filteredUsers = filteredUsers.filter(
        (user) => user.is_superuser === (this.state.usersSuper === "Admin")
      );
    }
    this.state.userSearchTerms.map((term) => {
      return (filteredUsers = filteredUsers.filter((item) =>
        `${item.first_name.toLowerCase()} ${item.last_name.toLowerCase()} 
        ${item.email.toLowerCase()} ${item.department.toLowerCase()}`.includes(
          term
        )
      ));
    });

    this.setState({ filteredUsers: filteredUsers, loading: false });
  }

  onUserSearchChange(event) {
    this.search = event.target.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          userSearchTerms: this.search.toLowerCase().split(" "),
          loading: true,
        },
        this.filterUsers
      );
    }, 800);
  }

  onPriceChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let item = {
        ID: parseInt(e.target.id),
        Name: e.target.name,
        Price: parseFloat(parseFloat(e.target.value).toFixed(2)),
      };
      putSubcategories(item).then();
    }, 800);
  };

  booleanFormatter(cell, row) {
    return <div>{cell ? "Y" : "N"}</div>;
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    if (this.state.loading) {
      return <LoadMask />;
    }

    let userModal;
    if (this.state.showUserModal) {
      userModal = (
        <UserModal
          show={this.state.showUserModal}
          user={this.state.selectedUser}
          onDelete={(id) => {
            this.setState({
              selectedUser: null,
              showUserModal: false,
              loading: true,
            });
            deleteUser(id).then(() => {
              fetchUsers().then((users) =>
                this.setState({ users: users, loading: true }, this.filterUsers)
              );
            });
          }}
          onSubmit={(user) => {
            this.setState({
              selectedUser: null,
              showUserModal: false,
              loading: true,
            });
            if (user.id) {
              putUser(user).then(() => {
                fetchUsers().then((users) =>
                  this.setState(
                    { users: users, loading: true },
                    this.filterUsers
                  )
                );
              });
            } else {
              postUser(user).then(() => {
                fetchUsers().then((users) =>
                  this.setState(
                    { users: users, loading: true },
                    this.filterUsers
                  )
                );
              });
            }
          }}
          onHide={() => {
            this.setState({ selectedUser: null, showUserModal: false });
          }}
        />
      );
    }

    let userTable = (
      <div className="users section">
        {userModal}
        <div className="d-flex align-items-center">
          <div className="title">Users</div>
          <Row className="ms-3 user-search-bar">
            <Col>
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2 search-input"
                onChange={this.onUserSearchChange}
              />
            </Col>
            <Col>
              <Button
                variant="primary"
                onClick={() => {
                  switch (this.state.usersActive) {
                    case "All":
                      this.setState(
                        { usersActive: "Active", loading: true },
                        this.filterUsers
                      );
                      break;
                    case "Active":
                      this.setState(
                        { usersActive: "Inactive", loading: true },
                        this.filterUsers
                      );
                      break;
                    default:
                      this.setState(
                        { usersActive: "All", loading: true },
                        this.filterUsers
                      );
                  }
                }}
                className={`glyphicon glyphicon-user btn-user-${this.state.usersActive}`}
              >
                <div className="button-text">{this.state.usersActive}</div>
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                onClick={() => {
                  switch (this.state.usersSuper) {
                    case "All":
                      this.setState(
                        { usersSuper: "Admin", loading: true },
                        this.filterUsers
                      );
                      break;
                    case "Admin":
                      this.setState(
                        { usersSuper: "Mortal", loading: true },
                        this.filterUsers
                      );
                      break;
                    default:
                      this.setState(
                        { usersSuper: "All", loading: true },
                        this.filterUsers
                      );
                  }
                }}
                className={`glyphicon glyphicon-king btn-user-${this.state.usersSuper}`}
              >
                <div className="button-text">{this.state.usersSuper}</div>
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                onClick={() => this.setState({ showUserModal: true })}
                className="glyphicon glyphicon-plus btn-circle btn-add"
              >
                <div className="hidden">Add</div>
              </Button>
            </Col>
          </Row>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Department</th>
              <th className="short-col">Active</th>
              <th className="short-col">Reports</th>
              <th className="short-col">Admin</th>
            </tr>
          </thead>
          <tbody>
            {this.state.filteredUsers.map((user) => {
              return (
                <tr
                  key={user.id}
                  onClick={() =>
                    this.setState({ showUserModal: true, selectedUser: user })
                  }
                >
                  <td>
                    {`${user.first_name} ${user.last_name}`}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.department}</td>
                  <td>{user.is_active ? "Y" : "N"}</td>
                  <td>{user.is_reporter ? "Y" : "N"}</td>
                  <td>{user.is_superuser ? "Y" : "N"}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );

    let settingModal;
    if (this.state.showSettingModal) {
      settingModal = (
        <SettingModal
          show={this.state.showSettingModal}
          setting={this.state.selectedSetting}
          onDelete={(id) => {
            this.setState({
              selectedSetting: null,
              showSettingModal: false,
              loading: true,
            });
            deleteSetting(id).then(() => {
              fetchSettings().then((settings) =>
                this.setState({ settings: settings, loading: false })
              );
            });
          }}
          onSubmit={(setting) => {
            this.setState({
              selectedSetting: null,
              showSettingModal: false,
              loading: true,
            });
            if (setting.id) {
              putSetting(setting).then(() => {
                fetchSettings().then((settings) =>
                  this.setState({ settings: settings, loading: false })
                );
              });
            } else {
              postSetting(setting).then(() => {
                fetchSettings().then((settings) =>
                  this.setState({ settings: settings, loading: false })
                );
              });
            }
          }}
          onHide={() => {
            this.setState({ selectedSetting: null, showSettingModal: false });
          }}
        />
      );
    }

    let settingTable = (
      <div className="settings section">
        {settingModal}
        <div className="d-flex align-items-center">
          <div className="title">Settings</div>
          <Button
            variant="primary"
            onClick={() => this.setState({ showSettingModal: true })}
            className="glyphicon glyphicon-plus btn-circle btn-add ms-3"
          >
            <div className="hidden">Add</div>
          </Button>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {this.state.settings.map((setting) => {
              return (
                <tr
                  key={setting.id}
                  onClick={() =>
                    this.setState({
                      showSettingModal: true,
                      selectedSetting: setting,
                    })
                  }
                >
                  <td>{setting.name}</td>
                  <td>{setting.value}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );

    let subcategoryPriceSetting = this.state.isSuperuser ? (
      <div className="subcategory section">
        <div className="pb-2 d-flex align-items-center">
          <div className="title">Subcategory Pricing</div>
          <Button
            className="ms-3"
            onClick={() => this.setState({ redirect: "/sale" })}
          >
            Sale
          </Button>
        </div>
        <div>
          {this.state.subcategories.map((item, i) => (
            <Row className="d-flex pt-3 align-items-center" key={i}>
              <Col sm="1">
                <img
                  src={require(`./images/subcategories/${item.ID}.svg`)}
                  alt={item.Name}
                />
              </Col>
              <Col sm="2">
                <div className="text-truncate">
                  {item.Name === "Other"
                    ? `${item.Name} ${
                        this.state.categoryMap[item.CategoryID].Name
                      }`
                    : item.Name}
                </div>
              </Col>
              <Col sm="2">
                <Form
                  onSubmit={this.handleSubmit}
                  className="d-flex align-items-center"
                >
                  <FormLabel className="mb-0">$</FormLabel>
                  <Form.Control
                    id={item.ID}
                    name={item.Name}
                    type="number"
                    className="ms-1 mb-0"
                    onChange={this.onPriceChange}
                    value={this.state[item.ID] || ""}
                  />
                </Form>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    ) : (
      ""
    );

    return (
      <Container>
        {userTable}
        {settingTable}
        {subcategoryPriceSetting}
        <div id="app-version">v2.0.0</div>
      </Container>
    );
  }
}

export default Admin;
