import React from "react";
import { Button, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Search from "./Search";
import { stringToDate } from "./services/UtilityService";
import {
  fetchLost,
  fetchCategories,
  fetchSubcategories,
} from "./services/DataService";
import LostCard from "./components/LostCard";
import Masonry from "react-masonry-component";

const masonryOptions = {
  transitionDuration: 0,
  itemSelector: ".card",
  columnWidth: ".card",
  percentPosition: true,
};

class Lost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      filtered: [],
      categories: {},
      subcategories: {},
      catID: 0,
      subCatID: 0,
      searchTerms: [],
      beforeDate: false,
      afterDate: false,
      maxResults: 100,
      view: "card",
      redirect: false,
    };
    this.getLost = this.getLost.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onSubcategoryChange = this.onSubcategoryChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.catID = "0";
    this.subCatID = "0";
  }

  componentDidMount() {
    Promise.all([fetchCategories(), fetchSubcategories("0")])
      .then(([categories, subcategories]) => {
        let categoryMap = {};
        categories.forEach((category) => {
          categoryMap[category["ID"]] = category["Name"];
        });
        let subcategoryMap = {};
        subcategories.forEach((subcategory) => {
          subcategoryMap[subcategory["ID"]] = subcategory["Name"];
        });
        this.setState(
          {
            categories: categoryMap,
            subcategories: subcategoryMap,
            loading: false,
          },
          this.getLost
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getLost() {
    let params = [];
    if (this.catID !== "0") {
      params.push("category=" + this.catID);
    }
    if (this.subCatID !== "0") {
      params.push("subcategory=" + this.subCatID);
    }
    fetchLost(params).then((items) => {
      this.setState({ items: items }, this.applyFilters);
    });
  }

  onCategoryChange(newCategory) {
    this.catID = newCategory;
    this.subCatID = "0";
    this.getLost();
  }

  onSubcategoryChange(newCategory) {
    this.subCatID = newCategory;
    this.getLost();
  }

  applyFilters() {
    let filtered = this.state.items;
    if (this.state.beforeDate) {
      filtered = filtered.filter(
        (item) => stringToDate(item.When) <= this.state.beforeDate
      );
    }
    if (this.state.afterDate) {
      filtered = filtered.filter(
        (item) => stringToDate(item.When) >= this.state.afterDate
      );
    }
    this.state.searchTerms.map((term) => {
      return (filtered = filtered.filter((item) =>
        `${this.state.categories[
          item.CatID
        ].toLowerCase()} ${this.state.subcategories[
          item.SubCatID
        ].toLowerCase()} ${item.Desc.toLowerCase()} ${item.Where.toLowerCase()} ${item.Attr.toLowerCase()} ${item.Ticket.toLowerCase()}`.includes(
          term
        )
      ));
    });
    this.setState({ filtered: filtered.slice(0, this.state.maxResults) });
  }

  onSearchChange(terms) {
    this.setState(
      { searchTerms: terms.toLowerCase().split(" ") },
      this.applyFilters
    );
  }

  render() {
    let search = (
      <Search
        className="mb-4"
        addAction="/lost/add"
        onCategoryChange={this.onCategoryChange}
        onSubcategoryChange={this.onSubcategoryChange}
        onSearchChange={this.onSearchChange}
        onViewChange={(view) => this.setState({ view: view })}
        onBeforeDateChange={(date) =>
          this.setState({ beforeDate: date }, this.applyFilters)
        }
        onAfterDateChange={(date) =>
          this.setState({ afterDate: date }, this.applyFilters)
        }
      />
    );
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    if (this.state.view === "list") {
      return (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Ticket</th>
                <th>Attributes</th>
                <th>Where Lost</th>
                <th>When Lost</th>
              </tr>
            </thead>
            <tbody>
              {this.state.filtered.map((lost) => {
                return (
                  <tr
                    key={lost.ID}
                    onClick={() =>
                      this.setState({ redirect: `/lost/${lost.ID}` })
                    }
                  >
                    <td>{lost.Ticket}</td>
                    <td>{lost.Attr}</td>
                    <td>{lost.Where}</td>
                    <td>{lost.When}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <div className="list-message-bottom">
            Showing a limit of {this.state.maxResults} results
            <Button
              style={{ marginLeft: "1em" }}
              onClick={() => {
                this.setState({ maxResults: this.state.maxResults * 2 });
                this.getLost();
              }}
              variant="primary"
            >
              Load More
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div>
        {search}
        <Masonry options={masonryOptions}>
          {this.state.filtered.map((item) => {
            return (
              <LostCard
                key={item.ID}
                item={item}
                onClick={() => this.setState({ redirect: `/lost/${item.ID}` })}
              />
            );
          })}
        </Masonry>
        <div className="list-message-bottom">
          Showing a limit of {this.state.maxResults} results
          <Button
            style={{ marginLeft: "1em" }}
            onClick={() => {
              this.setState({ maxResults: this.state.maxResults * 2 });
              this.getLost();
            }}
            variant="primary"
          >
            Load More
          </Button>
        </div>
      </div>
    );
  }
}

export default Lost;
